import React, { Component } from "react";
import { compose } from "redux";
import { Switch, Route } from "react-router-dom";
import { browserName, isMobile, isIE } from "react-device-detect";
import { Box, Grid, Link } from "@material-ui/core";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/styles";
import {
  toggleFullscreen,
  toggleTVGuide,
  toggleDrawer,
  handleWebviewPress,
  handleVideoPlay,
  handleVideoError,
  completeLoading,
  resetChannelError,
  resetVideoError,
  resetMediaURL,
  resetAuthMessage,
  resetUpdateMessage,
  showClock,
  completeVideoLoading
} from "../actions";
import {
  ADMOB_BANNER_ADUNITID,
  NAVBAR_HEIGHT,
  VBREAKUP_SM,
  VBREAKUP_MD,
  VBREAKUP_LG
} from "../constants";
import ProgramTitle from "../containers/programTitle";
import Footer from "../layout/footer";
import VideoPlayer from "../containers/videoPlayer";
import LoginView from "../containers/loginView";
import AdminMenuView from "../containers/adminMenuView";
import ChangePasswordView from "../containers/changePasswordView";
import AdSenseWrapper from "../containers/adSense";
// import Ticker from "../containers/ticker";

class Body extends Component {
  render() {
    const {
      orientation,
      tabId,
      screenHeight,
      hlsExtRequired
    } = this.props.main;
    const { isAuthenticated } = this.props.auth;
    const { classes } = this.props;
    const hlsExtStoreURL =
      browserName === "Chrome" || browserName === "Opera"
        ? "https://chrome.google.com/webstore/search/hls?hl=en-US"
        : browserName === "Firefox"
        ? "https://addons.mozilla.org/en-US/firefox/search/?q=hls"
        : isIE
        ? ""
        : "";
    return (
      <div className={classes.root}>
        <Grid container direction="column">
          <Switch>
            <Route
              path="/"
              exact
              render={() => (
                <Grid>
                  <Grid item className={classes.programTitle}>
                    {orientation === "PORTRAIT" &&
                    screenHeight > VBREAKUP_MD &&
                    tabId === 0 ? (
                      <ProgramTitle />
                    ) : null}
                  </Grid>
                  {hlsExtRequired ? (
                    !isMobile && (
                      <Grid>
                        <Box className={classes.hlsWarningStyle}>
                          <Box component="span" lang="en">
                            It is required to have a HLS (HTTP Live Streaming)
                            player extension or plugin in your desktop browser
                            to watch this link. If you already have one, please
                            ignore this message. Otherwise, follow&nbsp;
                            <Link href={hlsExtStoreURL} target="_blank">
                              <b>this link</b>
                            </Link>
                            &nbsp;to install it from your corresponding web
                            browser store.
                          </Box>
                          <br />
                          <br />
                          <Box compoent="span" lang="ko">
                            선택하신 채널을 보시려면 HLS 플레이어 플러그인이
                            필요합니다. 현재 사용하시는 웹브라우저에 설치가
                            안되어 있으신 분들은&nbsp;
                            <Link href={hlsExtStoreURL} target="_blank">
                              <b>다음 링크를</b>
                            </Link>
                            &nbsp;통해 웹브라우저 스토어에서 설치하세요.
                          </Box>
                        </Box>
                      </Grid>
                    )
                  ) : (
                    <Grid item>
                      <VideoPlayer />
                    </Grid>
                  )}
                  {/* <Grid>
                    <AdSenseWrapper />
                  </Grid> */}
                </Grid>
              )}
            />
            <Route
              path="/auth"
              render={() =>
                !isAuthenticated ? <LoginView /> : <AdminMenuView />
              }
            />
            <Route
              path="/change_password"
              render={() => <ChangePasswordView />}
            />
          </Switch>
          {(orientation === "PORTRAIT" && screenHeight > VBREAKUP_SM) ||
          (orientation === "LANDSCAPE" && screenHeight > VBREAKUP_MD) ? (
            <Grid item>
              <Footer />
            </Grid>
          ) : null}
        </Grid>
      </div>
    );
  }
}

const styles = theme => ({
  root: {
    // padding: theme.spacing(0.25)
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    backgroundColor: theme.palette.background.default,
    height: `calc(100vh - ${NAVBAR_HEIGHT * 2}px)`
  },
  programTitle: {
    padding: theme.spacing(1),
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0)
  },
  hlsWarningStyle: {
    backgroundColor: theme.palette.success.dark,
    color: theme.palette.text.default,
    marginTop: theme.spacing(5),
    marginLeft: theme.spacing(10),
    marginRight: theme.spacing(10),
    padding: theme.spacing(2)
  }
  // backgroundImage: {
  //   padding: theme.spacing(5),
  //   backgroundImage: `url(${PlayImage})`,
  //   backgroundColor: "#000000",
  //   width: `50%`,
  //   backgroundPosition: "center",
  //   // backgroundRepeat: no-repeat,
  //   backgroundSize: "cover"
  // }
});

const mapStateToProps = state => {
  const { auth, main, channels, programs, videos } = state;
  return {
    auth,
    main,
    channels,
    programs,
    videos
  };
};

const mapDispatchToProps = dispatch => {
  return {
    toggleFullscreen: isFullscreen => dispatch(toggleFullscreen(isFullscreen)),
    handleWebviewPress: canGoBack => dispatch(handleWebviewPress(canGoBack)),
    handleVideoPlay: idx => dispatch(handleVideoPlay(idx)),
    handleVideoError: (error, channelIdx) =>
      dispatch(handleVideoError(error, channelIdx)),
    toggleDrawer: isOpen => dispatch(toggleDrawer(isOpen)),
    closeTVGuide: isShown => dispatch(toggleTVGuide(isShown)),
    completeLoading: () => dispatch(completeLoading()),
    resetMediaURL: () => dispatch(resetMediaURL()),
    resetChannelError: () => dispatch(resetChannelError()),
    resetVideoError: () => dispatch(resetVideoError()),
    resetAuthMessage: () => dispatch(resetAuthMessage()),
    resetUpdateMessage: () => dispatch(resetUpdateMessage()),
    showClock: isShown => dispatch(showClock(isShown)),
    completeVideoLoading: () => dispatch(completeVideoLoading())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(compose(withStyles(styles))(Body));
