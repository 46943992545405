import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  resetVideoError,
  resetChannelMessage,
  resetProgramMessage,
  resetAuthMessage
} from "../actions";
import SnackbarWrapper from "../common/snackbar";

class AlertView extends Component {
  state = {
    showSnackbar: false,
    module: null,
    variant: null
  };

  componentWillReceiveProps(nextProps) {
    const { main, channels, programs, auth } = nextProps;
    if (!!main.videoError) {
      this.setState({
        module: "main",
        variant: "error",
        showSnackbar: true
      });
    } else if (!!channels.error || !!channels.message) {
      this.setState({
        module: "channels",
        variant: !!channels.error ? "error" : "success",
        showSnackbar: true
      });
    } else if (!!programs.error || !!programs.message) {
      this.setState({
        module: "programs",
        variant: !!programs.error ? "error" : "success",
        showSnackbar: true
      });
    } else if (!!auth.error || !!auth.message) {
      this.setState({
        module: "auth",
        variant: !!auth.error ? "error" : "success",
        showSnackbar: true
      });
    }
  }

  _handleClose = () => {
    const { module } = this.state;
    this.setState({ showSnackbar: false });
    module === "main" && this.props.resetVideoError();
    module === "channels" && this.props.resetChannelMessage();
    module === "programs" && this.props.resetProgramMessage();
    module === "auth" && this.props.resetAuthMessage();
    this.setState({
      module: null,
      vaiant: null
    });
  };

  render() {
    const { main, channels, programs, auth } = this.props;
    const variant =
      !!main.videoError || !!channels.error || !!programs.error || !!auth.error
        ? "error"
        : "success";

    const snackbarMessage =
      main.videoError ||
      channels.error ||
      channels.message ||
      programs.error ||
      programs.message ||
      auth.error ||
      auth.message;
    return (
      <SnackbarWrapper
        open={this.state.showSnackbar}
        variant={variant}
        message={snackbarMessage}
        onClose={this._handleClose}
        duration={3000}
      />
    );
  }
}

const mapStateToProps = state => {
  const { main, channels, programs, auth } = state;
  return {
    main,
    channels,
    programs,
    auth
  };
};

const mapDispatchToProps = dispatch => {
  return {
    // resetMainMessage: () => dispatch(resetMainMessage()),
    resetVideoError: () => dispatch(resetVideoError()),
    resetChannelMessage: () => dispatch(resetChannelMessage()),
    resetProgramMessage: () => dispatch(resetProgramMessage()),
    resetAuthMessage: () => dispatch(resetAuthMessage())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AlertView);
