import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { isMobile } from "react-device-detect";
import { Box, GridList, GridListTile } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import {
  getVideoList,
  setVideoURL,
  completeLoading,
  toggleDrawer,
  resetMediaURL
  // resetChannelError,
} from "../actions";
import { DRAWER_WIDTH, MAX_YOUTUBE_RESULTS } from "../constants";

class PopularView extends Component {
  state = {
    videoIdx: 0
  };

  componentDidMount() {
    this.videoIntId = setInterval(
      () => this.props.getVideoList(),
      30 * 60 * 1000
    );
    document.addEventListener("keypress", this._handleKeyDown);
    !!this.refs["video0"] && this.refs["video0"].focus();
  }

  componentWillUnmount() {
    clearInterval(this.videoIntId);
    document.removeEventListener("keypress", this._handleKeyDown);
  }

  _handleKeyDown = e => {
    e.preventDefault();
    let prevIdx;
    let nextIdx;
    const upperLimit = MAX_YOUTUBE_RESULTS - 1;
    switch (e.key) {
      case "a":
        prevIdx = this.state.videoIdx - 1;
        if (prevIdx < 0) prevIdx = 0;
        this.refs[`video${prevIdx}`].focus();
        this.setState({ videoIdx: prevIdx });
        break;
      case "d":
        nextIdx = this.state.videoIdx + 1;
        if (nextIdx > upperLimit) nextIdx = upperLimit;
        this.refs[`video${nextIdx}`].focus();
        this.setState({ videoIdx: nextIdx });
        break;
      case "s":
        nextIdx = this.state.videoIdx + 2;
        if (nextIdx > upperLimit) nextIdx = upperLimit;
        this.refs[`video${nextIdx}`].focus();
        this.setState({ videoIdx: nextIdx });
        break;
      case "w":
        prevIdx = this.state.videoIdx - 2;
        if (prevIdx < 0) prevIdx = 0;
        this.refs[`video${prevIdx}`].focus();
        this.setState({ videoIdx: prevIdx });
        break;
      default:
        break;
    }
  };

  _handleVideoClick = url => {
    this.props.handleVideoClick(url);
    setTimeout(() => this.props.toggleDrawer(false), 3000);
  };

  _handleVideoKeyPress = (url, e) => {
    if (e.keyCode === 13) {
      this._handleVideoClick(url);
    }
  };

  render() {
    const { classes } = this.props;
    const { videos } = this.props.videos;
    const { mediaURL } = this.props.main;
    return (
      <div className={classes.root}>
        <GridList cellHeight={166} className={classes.gridList} cols={2}>
          {videos.map((video, idx) => {
            const videoURL = `https://www.youtube.com/embed/${
              video.id.videoId
            }`;
            return (
              <GridListTile
                key={idx}
                onClick={() => this._handleVideoClick(videoURL)}
                onKeyDown={e => this._handleVideoKeyPress(videoURL, e)}
                className={classes.gridlistTile}
              >
                <Box
                  ref={`video${idx}`}
                  tabIndex={idx}
                  color={"primary.main"}
                  border={mediaURL === videoURL ? 1 : 0}
                  className={classes.videoTile}
                >
                  <img
                    className={classes.image}
                    src={video.snippet.thumbnails.default.url}
                    alt={video.snippet.title}
                  />
                  <Box
                    className={classes.box}
                    color={"text.default"}
                    height={64}
                    fontSize={11}
                  >
                    {video.snippet.title.replace(/&#39;|&quot;/gi, "'")}
                  </Box>
                </Box>
              </GridListTile>
            );
          })}
        </GridList>
      </div>
    );
  }
}

PopularView.propType = {
  classes: PropTypes.object.isRequired
};

const styles = theme => ({
  root: {
    width: isMobile ? DRAWER_WIDTH : `calc(${DRAWER_WIDTH - 27}px)`,
    margin: theme.spacing(0.5),
    marginRight: theme.spacing(0.25)
  },
  gridList: {
    backgroundColor: theme.palette.background.default
  },
  gridlistTile: {
    backgroundColor: theme.palette.background.dark
  },
  videoTile: {
    "&:active": {
      borderWidth: 2,
      borderColor: theme.palette.primary.main
    }
  },
  image: {
    padding: theme.spacing(0.15),
    display: "block",
    width: "100%",
    height: "auto"
  },
  box: {
    padding: theme.spacing(0.25)
  }
});

const mapStateToProps = state => {
  const { main, videos } = state;
  return {
    main,
    videos
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getVideoList: () => dispatch(getVideoList()),
    handleVideoClick: url => dispatch(setVideoURL(url)),
    completeLoading: () => dispatch(completeLoading()),
    resetMediaURL: () => dispatch(resetMediaURL()),
    toggleDrawer: showDrawer => dispatch(toggleDrawer(showDrawer))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(PopularView));
