import React, { Component, Fragment } from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { isIOS, browserName } from "react-device-detect";
import axios from "axios";
import {
  Badge,
  Box,
  Button,
  ButtonBase,
  ClickAwayListener,
  Grid,
  GridListTile,
  IconButton,
  LinearProgress,
  Link,
  Tooltip
} from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import {
  blue,
  blueGrey,
  cyan,
  grey,
  indigo,
  lightBlue,
  orange,
  teal
} from "@material-ui/core/colors";
import moment from "moment";
import {
  getProgramList,
  setMediaURL,
  setChannelURL,
  setProgramDetail,
  completeLoading,
  resetMediaURL,
  handleVideoPlay,
  setVideoError,
  setHLSExtRequired
  // resetChannelError,
} from "../actions";
import {
  ERROR_VIDEO_LOADING,
  ERROR_NO_PROGRAM_TITLE,
  ERROR_NO_PROGRAM_DESCRIPTION,
  MAX_LOADING_RESET_TIMEOUT,
  DRAWER_WIDTH
} from "../constants";
import withKeyboard from "../hoc/keyboardShortcut";
// import SnackbarWrapper from "../common/snackbar";

let timestamp = moment(new Date());

const getTimeDiffInSeconds = time => {
  if (isNaN(time)) {
    return 0;
  }
  const now = new Date();
  return (now - time) / 1000;
};

class ChannelView extends Component {
  constructor(props) {
    super(props);
    const { main, channels } = props;
    this.state = {
      loopCount: 0,
      progress: 0,
      channelPressed: false,
      channelIdx:
        channels.channels.findIndex(
          item => item.name === main.currentChannel
        ) || 0,
      channelLinkIdx: main.currentChannelIdx || -1
    };
  }

  componentDidMount() {
    if (!this.progressIntId) {
      this.progressIntId = setInterval(
        () => this._getProgramProgress(),
        10 * 1000
      );
    }
    this.refs["leftpaneRoot"].focus();
  }

  componentWillUnmount() {
    clearInterval(this.progressIntId);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { channels } = this.props.channels;
    let channelName;
    if (nextProps.location.pathname !== "/") {
      return false;
    }
    if (!!channels[this.state.channelIdx]) {
      channelName = channels[this.state.channelIdx].name;
    }
    switch (nextProps.keyCode) {
      case 65:
      case 37: // a and left arrow keys
        let prevLinkIdx = this.state.channelLinkIdx;
        prevLinkIdx -= 1;
        if (prevLinkIdx === -1) {
          this.refs[`channel${this.state.channelIdx}`].focus();
        } else {
          this.refs[`${channelName + prevLinkIdx}`] &&
            this.refs[`${channelName + prevLinkIdx}`].focus();
        }
        this.setState({ channelLinkIdx: prevLinkIdx });
        break;
      case 68:
      case 39: // d and right arrow keys
        let nextLinkIdx = this.state.channelLinkIdx;
        if (nextLinkIdx < channels[this.state.channelIdx].urls.length - 1) {
          nextLinkIdx += 1;
        }
        !!this.refs[`${channelName + nextLinkIdx}`] &&
          this.refs[`${channelName + nextLinkIdx}`].focus();
        this.setState({ channelLinkIdx: nextLinkIdx });
        break;
      case 83:
      case 40: // s and down arrow keys
        let nextIdx = this.state.channelIdx + 1;
        if (!this.refs[`channel${nextIdx}`]) {
          nextIdx = this.state.channelIdx;
        }
        this.refs[`channel${nextIdx}`].focus();
        this.setState({ channelIdx: nextIdx, channelLinkIdx: -1 });
        break;
      case 87:
      case 38: // w and up arrow keys
        let prevIdx = this.state.channelIdx - 1;
        if (!this.refs[`channel${prevIdx}`]) {
          prevIdx = this.state.channelIdx;
        }
        this.refs[`channel${prevIdx}`].focus();
        this.setState({ channelIdx: prevIdx, channelLinkIdx: -1 });
        break;
      default:
        return false;
    }
  }

  _handleChannelNameClick = url => {
    window.open(url, "_blank");
  };

  // _handleChannelNameKeyDown = (url, e) => {
  //   console.log("channel name keydown");
  //   e.preventDefault();
  //   if (e.keyCode === 13) {
  //     this._handleChannelNameClick(url);
  //   }
  // };

  _checkCorsHeader = url => {
    // axios
    //   .get(url)wwwssss
    //   .then(response => console.log(response.headers))
    //   .catch(err => console.log(err));
    const filters = [
      "199.21.",
      "198.144.",
      // "www.youtv365.com:1935",
      "23.237.112.138:9083",
      "50.7.118.178:9083",
      "218.38.",
      "rsc.cdn77.org",
      "huya.com",
      "cnnindonesia.com",
      "1.214."
      // "122.129."
    ];
    // if (browserName === "Firefox") {
    //   filters.push("live.obs");
    // }
    let result = false;
    for (const filter of filters) {
      if (url.indexOf(filter) > -1) {
        result = true;
        break;
      }
    }
    this.props.setHLSExtRequired(result);
    return result;
  };

  _handleSpecialChannels = channel => {
    if (channel === "MBC 드라마넷") {
      const headers = {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
        Host: "live.enc0867live.com",
        Origin: "http://live.enc0867live.com",
        Referer: "http://live.enc0867live.com/",
        "User-Agent":
          "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/76.0.3809.100 Safari/537.36",
        Cookie:
          "__cfduid=d5d0a3248717b61dc6da9af0316bf471d1557613313; UCHAT_SETTINGS=zRsoHcsKQ4wWbpwzMA7SlUJitsT9R1eP; nopo=hide; ci_session_tvkim_=b5f144d2f955ded3b6769f7d1bc48eeceb07459d",
        "Access-Control-Allow-Origin": "*"
      };
      const data = {
        idx: 25
      };
      axios
        .post("http://live.enc0867live.com/live/ajax_stream", data, headers)
        .then(res => console.log(res))
        .catch(err => console.log(err));
      return true;
    }
    return false;
  };

  _handleChannelNoClick = (channel, url, idx) => {
    // const { currentChannel, currentChannelIdx } = this.props.main;
    clearTimeout(this.videoErrorIntId);
    this.props.history.push("/");
    this.setState({ progress: 0 });

    if (this._handleSpecialChannels(channel)) {
      return false;
    }

    const isCorsHeader = this._checkCorsHeader(url);
    if (isCorsHeader || isIOS) {
      window.open(url, "_blank");
      this.props.resetMediaURL();
      url = "";
    } else {
      this.videoErrorIntId = setTimeout(() => {
        if (!this.props.main.isPlaying) {
          this.props.setVideoError(ERROR_VIDEO_LOADING(channel, idx + 1), idx);
          this.refs[`${channel + idx}`].focus();
        }
      }, MAX_LOADING_RESET_TIMEOUT);
    }
    this.props.handleChannelNoPress(channel, url, idx);
    if (getTimeDiffInSeconds(this.props.programs.lastCheckedTime) > 10 * 60) {
      this.props.getProgramList(true);
    }
    this.props.setProgramDetail(channel);
    if (isCorsHeader || isIOS) {
      this.props.handleVideoPlay(idx);
      this.props.completeLoading();
    }
  };

  _getProgramProgress = () => {
    // console.log("_getProgramProgress", new Date());
    const now = moment(new Date());
    const timeDiff = moment.duration(now.diff(timestamp), "seconds");
    if (timeDiff > 2) {
      const { startTime, finishTime } = this.props.programs;
      const startHours = parseInt(startTime);
      let finishHours = parseInt(finishTime);
      if (!(isNaN(startHours) || isNaN(finishHours))) {
        const date = new Date();
        let hours = date.getHours();
        let minutes = date.getMinutes();
        if (hours < startHours) {
          hours += 24;
        }
        const start = parseInt(startTime.slice(-2));
        if (finishHours < startHours) {
          finishHours += 24;
        }
        const finish =
          parseInt(finishTime.slice(-2)) + (finishHours - startHours) * 60;
        let progress = 0;
        if (hours > startHours) minutes += (hours - startHours) * 60;
        if (!isNaN(start) && minutes > start && finish !== start) {
          progress = (minutes - start) / (finish - start - 4);
        }
        if (progress >= 1) {
          // console.log("_getProgramProgress", new Date().toLocaleTimeString());
          // this.props.getProgramList(true);
          if (this.state.loopCount % 10 === 0) {
            this.setState({ loopCount: 1 });
            this.props.getProgramList(true);
          }
          this.setState({
            progress: 0,
            loopCount: this.state.loopCount + 1
          });
        } else if (!isNaN(progress)) {
          this.setState({ progress });
        }
        timestamp = moment(new Date());
      } else {
        this.setState({ progress: 0 });
      }
    }
  };

  _getProgramIdx = (programs, channel) => {
    if (programs === undefined || programs.length === 0) {
      return -1;
    }
    return programs.findIndex(
      program =>
        program.channel.replace(/\s+/g, "").toUpperCase() ===
        channel.replace(/\s+/g, "").toUpperCase()
    );
  };

  _renderProgram = name => {
    const { classes } = this.props;
    const { programs, loading } = this.props.programs;
    const idx = this._getProgramIdx(programs, name);
    return (
      <Grid container direction="column" className={classes.programContainer}>
        {idx > -1 ? (
          <Fragment>
            <Grid item>
              <Box
                display={"flex"}
                fontSize={12}
                className={classes.currentProgramText}
              >
                <Box mr={0.5}>Now:</Box>
                {programs[idx].program_desc !== undefined ? (
                  <Fragment>
                    <Tooltip
                      title={
                        !programs[idx].program_desc.includes("Paypal Donate")
                          ? programs[idx].program_desc
                          : ERROR_NO_PROGRAM_DESCRIPTION
                      }
                      classes={{ tooltip: classes.programTooltip }}
                      enterTouchDelay={100}
                      leaveTouchDelay={5500}
                      interactive={true}
                      placement="right"
                      disableFocusListener
                      // disableHoverListener
                      disableTouchListener
                    >
                      <Box className={classes.programText}>
                        {programs[idx].from}&nbsp;
                        {programs[idx].program}
                      </Box>
                    </Tooltip>
                  </Fragment>
                ) : (
                  <Box>
                    <Link
                      href={`https://m.search.daum.net/search?w=tot&DA=YZR&t__nil_searchbox=btn&sug=&sugo=&q=${programs[
                        idx
                      ].program.replace(/(1부)|(2부)|(3부)|(4부)|<|>/g, "")}`}
                      target="_blank"
                      color="inherit"
                      underline="hover"
                    >
                      {`${programs[idx].from} ${programs[idx].program}`}
                    </Link>
                  </Box>
                )}
              </Box>
            </Grid>
            <Grid item>
              <Box display={"flex"} fontSize={12}>
                <Box mr={0.5}>Next:</Box>
                {programs[idx].next_program_desc !== undefined ? (
                  <Fragment>
                    <Tooltip
                      title={
                        !programs[idx].next_program_desc.includes(
                          "Paypal Donate"
                        )
                          ? programs[idx].next_program_desc
                          : ERROR_NO_PROGRAM_DESCRIPTION
                      }
                      classes={{ tooltip: classes.programTooltip }}
                      enterTouchDelay={100}
                      leaveTouchDelay={5500}
                      interactive={true}
                      placement="right"
                      disableFocusListener
                      // disableHoverListener
                      disableTouchListener
                    >
                      <Box className={classes.programText}>
                        {`${programs[idx].till} ${programs[idx].next}`}
                      </Box>
                    </Tooltip>
                  </Fragment>
                ) : (
                  <Box>
                    <Link
                      href={`https://m.search.daum.net/search?w=tot&DA=YZR&t__nil_searchbox=btn&sug=&sugo=&q=${programs[
                        idx
                      ].next.replace(/(1부)|(2부)|(3부)|(4부)|<|>/g, "")}`}
                      target="_blank"
                      color="inherit"
                      underline="hover"
                    >
                      {programs[idx].till}&nbsp;{programs[idx].next}
                    </Link>
                  </Box>
                )}
              </Box>
            </Grid>
          </Fragment>
        ) : (
          <Box fontSize={12}>{ERROR_NO_PROGRAM_TITLE}</Box>
        )}
      </Grid>
    );
  };

  _renderChannels = () => {
    const { classes } = this.props;
    const {
      currentChannel,
      currentChannelIdx,
      isPlaying,
      loading
    } = this.props.main;
    const { channels } = this.props.channels;
    const colors = [
      blueGrey[400],
      blueGrey[100],
      blueGrey[300],
      blueGrey[500],
      blueGrey[200]
    ];
    const buttonColors = [teal[700], teal[800], teal[900]];
    return (
      <Fragment>
        {channels.map((channel, idx) => {
          let flag = "";
          const channelName =
            channel.display_name !== "" ? channel.display_name : channel.name;
          if (
            channel.country_code !== "" &&
            channel.country_code !== undefined
          ) {
            flag = require(`../../assets/images/flags/${channel.country_code}.svg`);
          }
          return channel.urls.length > 0 ? (
            <Box
              key={idx}
              borderLeft={8}
              className={classes.borderSelected}
              style={
                channel.name !== currentChannel
                  ? { borderColor: colors[idx % 5] }
                  : null
              }
            >
              <GridListTile className={classes.gridListTile}>
                <Grid
                  container
                  className={classes.channelContainer}
                  direction="row"
                  justify="flex-start"
                  alignItems="center"
                >
                  <Grid item>
                    {channel.country_code !== "" ? (
                      <img src={flag} className={classes.flagImage} />
                    ) : null}
                  </Grid>
                  <Grid item>
                    <Button
                      ref={`channel${idx}`}
                      onClick={() =>
                        this._handleChannelNameClick(channel.tvguide_url)
                      }
                      variant={"contained"}
                      color={
                        channel.name === currentChannel
                          ? "primary"
                          : "secondary"
                      }
                      className={classes.channelName}
                      aria-label={channelName}
                    >
                      {channelName}
                    </Button>
                  </Grid>
                  <Grid item className={classes.channelButtonContainer}>
                    {channel.urls.map((url, idx) => {
                      if (idx < 3) {
                        const buttonColor =
                          currentChannel === channel.name &&
                          currentChannelIdx === idx
                            ? orange[500]
                            : buttonColors[idx % 3];
                        const invisible = url.url.includes("1.214")
                          ? false
                          : true;
                        return (
                          <Badge
                            badgeContent={""}
                            color="secondary"
                            variant="dot"
                            invisible={invisible}
                            classes={{ badge: classes.badgePosition }}
                          >
                            <ButtonBase
                              key={channel.name + String(idx)}
                              ref={`${channel.name + idx}`}
                              aria-label={`${channelName} link ${idx + 1}}`}
                              onClick={() =>
                                this._handleChannelNoClick(
                                  channel.name,
                                  url.url,
                                  idx
                                )
                              }
                              className={classes.channelButton}
                              focusRipple={true}
                              style={{
                                backgroundColor: buttonColor,
                                borderColor: buttonColor
                              }}
                            >
                              {idx + 1}
                            </ButtonBase>
                          </Badge>
                        );
                      }
                    })}
                  </Grid>
                </Grid>
                <Grid item className={classes.progressBar}>
                  {currentChannel === channel.name ? (
                    loading ? (
                      <LinearProgress />
                    ) : (
                      <LinearProgress
                        variant="determinate"
                        value={this.state.progress * 100}
                      />
                    )
                  ) : null}
                </Grid>
                <Grid>{this._renderProgram(channel.name)}</Grid>
              </GridListTile>
            </Box>
          ) : null;
        })}
      </Fragment>
    );
  };

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root} ref={"leftpaneRoot"}>
        {this._renderChannels()}
      </div>
    );
  }
}

ChannelView.propType = {
  classes: PropTypes.object.isRequired
};

const styles = theme => ({
  root: {
    paddingTop: theme.spacing(0.2),
    paddingBottom: theme.spacing(0)
  },
  gridListTile: {
    width: DRAWER_WIDTH
  },
  channelContainer: {
    paddingTop: theme.spacing(0.6),
    paddingLeft: theme.spacing(0.8),
    paddingBottom: theme.spacing(0.6),
    backgroundColor: grey[800],
    alignItems: "center"
  },
  channelName: {
    "&:hover": {
      color: cyan[100]
    },
    color: theme.palette.text.default,
    width: theme.spacing(13),
    minHeight: theme.spacing(4),
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(0),
    margin: theme.spacing(0),
    alignItems: "center",
    fontSize: 12,
    fontWeight: "bold",
    flexGrow: 1
  },
  channelButtonContainer: {
    paddingLeft: theme.spacing(1)
  },
  channelButton: {
    color: theme.palette.text.default,
    padding: theme.spacing(0),
    marginRight: theme.spacing(0.5),
    height: theme.spacing(4),
    width: theme.spacing(3.5),
    fontSize: 14,
    "&:hover": {
      color: cyan[200]
    },
    "&:focus": {
      outline: "none",
      outlineOffset: "none"
    },
    fontWeight: "bold",
    borderRadius: 4,
    borderWidth: 1
    // borderColor: "black"
  },
  programContainer: {
    backgroundColor: grey[900],
    paddingLeft: 10,
    paddingRight: 27,
    paddingTop: 5,
    paddingBottom: 5
  },
  currentProgramText: {
    color: theme.palette.primary.light
  },
  progressBar: {
    paddingLeft: 10,
    paddingRight: 28
  },
  borderSelected: {
    borderColor: theme.palette.primary.main
  },
  flagImage: {
    height: 24,
    // paddingTop: theme.spacing(0.8),
    // paddingLeft: theme.spacing(0.5),
    marginTop: theme.spacing(0.5),
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(0.7),
    borderColor: theme.palette.text.default,
    borderWidth: theme.spacing(0.5)
  },
  infoIconContainer: {
    marginLeft: theme.spacing(0.5)
  },
  infoIcon: {
    fontSize: 14,
    color: theme.palette.text.default
  },
  programTooltip: {
    backgroundColor: theme.palette.background.white,
    color: theme.palette.text.black,
    fontSize: 17
  },
  programText: {
    paddingLeft: 5,
    "&:hover": {
      textDecorationLine: "underline"
    }
  },
  badgePosition: {
    top: "-5%",
    right: "55%"
  }
});

const mapStateToProps = state => {
  const { main, channels, categories, programs } = state;
  return {
    main,
    channels,
    categories,
    programs
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getProgramList: silentRun => dispatch(getProgramList(silentRun)),
    handleChannelNoPress: (channel, url, idx) =>
      dispatch(setMediaURL(channel, url, idx)),
    handleChannelNamePress: url => dispatch(setChannelURL(url)),
    setProgramDetail: channel => dispatch(setProgramDetail(channel)),
    completeLoading: () => dispatch(completeLoading()),
    resetMediaURL: () => dispatch(resetMediaURL()),
    handleVideoPlay: idx => dispatch(handleVideoPlay(idx)),
    setVideoError: (error, idx) => dispatch(setVideoError(error, idx)),
    setHLSExtRequired: isRequired => dispatch(setHLSExtRequired(isRequired))
    // resetChannelError: () => dispatch(resetChannelError()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  compose(
    withKeyboard,
    withStyles(styles),
    withRouter
  )(ChannelView)
);
