import {
  GET_GEO_LOCATION,
  GET_WEATHER_INFO_SUCCESS,
  GET_WEATHER_INFO_FAILURE,
  SET_BATTERY_INFO,
  GET_DATETIME_INFO,
  SET_FORECAST_INFO,
  SET_CITY_LIST,
  ADD_CITY,
  REMOVE_CITY,
  REORDER_CITY,
  SHOW_CLOCK,
} from '../constants';

const initialState = {
  // battery info
  batteryLevel: 0,
  charging: false,
  // current date time info
  date: new Date(),
  // large clock
  showClock: false,
  //  current location info
  latitude: 0,
  longitude: 0,
  // weather info
  cities: [],
  current: null,
  forecast: null,
  isLoading: false,
  temperature: null,
  temp_min: 0,
  temP_max: 0,
  icon: null,
  weatherCondition: null,
  error: null
}

const infoReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_GEO_LOCATION: {
      return {
        ...state,
        latitude: action.payload.latitude,
        longitude: action.payload.longitude
      };
    }
    case GET_WEATHER_INFO_SUCCESS: {
      const { weatherInfo, weatherType } = action.payload;
      if (weatherType === "current") {
        return {
          ...state,
          current: action.payload.weatherInfo,
          temperature: (Math.round(weatherInfo.main.temp * 10) / 10).toFixed(1),
          temp_min: weatherInfo.main.temp_min,
          temp_max: weatherInfo.main.temp_max,
          icon: `http://openweathermap.org/img/w/${weatherInfo.weather[0].icon}.png`
        };
      }
      return {
        ...state,
        forecast: weatherInfo
      };
    }
    case GET_WEATHER_INFO_FAILURE:
      return {
        ...state,
        error: action.payload
      };
    case SET_BATTERY_INFO:
      return {
        ...state,
        batteryLevel: action.payload.batteryLevel,
        charging: action.payload.charging
      };
    case GET_DATETIME_INFO:
      return {
        ...state,
        date: action.payload
      };
    case SET_FORECAST_INFO:
      return {
        ...state,
        cities: action.payload
      };
    case SET_CITY_LIST:
      return {
        ...state,
        cities: action.payload
      };
    case ADD_CITY: {
      console.log(state.cities, action.payload);
      let index = state.cities.findIndex(el => el.id === action.payload.id);
      if (index === -1) {
        return {
          ...state,
          cities: [...state.cities, action.payload]
        };
      }
      return state;
    }
    case REMOVE_CITY:
      return {
        ...state,
        cities: state.cities.filter((_, i) => i !== action.payload)
      };
    case REORDER_CITY:
      return {
        ...state,
        cities: action.payload
      };
    case SHOW_CLOCK:
      return {
        ...state,
        showClock: action.payload
      };
    default:
      return state;
  }
}

export default infoReducer;