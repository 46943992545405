import {
  START_LOGIN,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  HANDLE_TEXT_INPUT,
  START_LOGOUT,
  LOGOUT_SUCCESS,
  LOGOUT_FAILURE,
  START_PASSWORD_CHANGE,
  PASSWORD_CHANGE_SUCCESS,
  PASSWORD_CHANGE_FAILURE,
  RESET_LOGIN_ERROR,
  RESET_ALL_PASSWORDS,
  LOGIN_RETRY_DURATION,
  LOGIN_NO_OF_ATTEMPTS,
  RESET_AUTH_MESSAGE
} from "../constants";

const initialState = {
  loading: false,
  username: "",
  password: "",
  oldPassword: "",
  password1: "",
  password2: "",
  firstName: "",
  lastName: "",
  userPrivilege: null,
  isAuthenticated: false,
  rememberMe: false,
  token: null,
  error: null,
  failedAttempt: 0,
  lastAttemptTimeStamp: null,
  isPermitted: true,
  message: null
};

const getTimeLeft = timestamp => {
  console.log(LOGIN_RETRY_DURATION, new Date(), timestamp);
  console.log(new Date() - timestamp);
  return (
    LOGIN_RETRY_DURATION - Math.floor((new Date() - timestamp) / (60 * 1000))
  );
};

export default function authReducer(state = initialState, action) {
  let isPermitted;
  let error;
  let failedAttempt;
  let minuteLeft;

  switch (action.type) {
    case START_LOGIN: {
      minuteLeft = getTimeLeft(state.lastAttemptTimeStamp);
      console.log("minuteLeft", minuteLeft);
      if (minuteLeft > 0 && state.failedAttempt >= LOGIN_NO_OF_ATTEMPTS) {
        error = `Too many failed attempts, please try again in ${minuteLeft} ${
          minuteLeft > 1 ? "minutes" : "minute"
        }.`;
        return {
          ...state,
          loading: false,
          error,
          isPermitted: false,
          username: "",
          password: ""
        };
      } else {
        if (minuteLeft <= 0 && state.failedAttempt >= LOGIN_NO_OF_ATTEMPTS) {
          failedAttempt = 0;
        } else {
          failedAttempt = state.failedAttempt;
        }
      }
      return {
        ...state,
        loading: true,
        error: null,
        isPermitted: true,
        failedAttempt
      };
    }
    case LOGIN_SUCCESS: {
      const {
        token,
        username,
        userPrivilege,
        firstName,
        lastName
      } = action.payload;
      return {
        ...state,
        loading: false,
        isAuthenticated: true,
        isPermitted: true,
        token: token,
        username: username,
        userPrivilege,
        password: "",
        firstName,
        lastName,
        error: null,
        failedAttempt: 0
      };
    }
    case LOGIN_FAILURE: {
      // minuteLeft = getTimeLeft(state.lastAttemptTimeStamp);
      // failedAttempt = state.failedAttempt + 1;
      // if (minuteLeft > 0 && state.failedAttempt > LOGIN_NO_OF_ATTEMPTS) {
      //   error = `Too many failed attempts, please try again in ${minuteLeft} ${
      //     minuteLeft > 1 ? "minutes" : "minute"
      //   }.`;
      //   isPermitted = false;
      // } else {
      //   error = action.payload;
      //   isPermitted = true;
      // }
      const now = new Date();
      return {
        ...state,
        loading: false,
        isAuthenticated: false,
        username: "",
        password: "",
        error: action.payload,
        failedAttempt: state.failedAttempt + 1,
        lastAttemptTimeStamp: now.getTime()
        // isPermitted
      };
    }
    case RESET_LOGIN_ERROR:
      return {
        ...state,
        error: null
      };
    case RESET_ALL_PASSWORDS:
      return {
        ...state,
        oldPassword: "",
        password1: "",
        password2: ""
      };
    case HANDLE_TEXT_INPUT:
      return {
        ...state,
        [action.payload.inputField]: action.payload.value
      };
    case START_LOGOUT:
      return {
        ...state,
        loading: true,
        error: null
      };
    case LOGOUT_SUCCESS:
      return {
        ...state,
        loading: false,
        isAuthenticated: false,
        token: null,
        username: "",
        password: "",
        firstname: "",
        lastName: "",
        error: null,
        message: action.payload
      };
    case LOGOUT_FAILURE:
      return {
        ...state,
        loading: false,
        // isAuthenticated: false,
        error: action.payload
      };
    case START_PASSWORD_CHANGE:
      return {
        ...state,
        loading: true,
        error: null,
        oldPassword: "",
        password1: "",
        password2: ""
      };
    case PASSWORD_CHANGE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        oldPassword: "",
        password1: "",
        password2: "",
        message: action.payload
      };
    case PASSWORD_CHANGE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        oldPassword: "",
        password1: "",
        password2: ""
      };
    case RESET_AUTH_MESSAGE:
      return {
        ...state,
        error: null,
        message: null
      };
    default:
      return state;
  }
}
