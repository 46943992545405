import {
  SET_SETTING_ITEM,
} from '../constants';

const initialState = {
  allowCellular: false,
  showProgramTitle: true,
  showAdminLogin: false,
  autoFullscreen: false,
  showDeveloperMenu: false,
  playInBackground: false,
  showExpandedControl: true,
  useExternalPlayer: false,
  weatherUnitMetric: true, // "metric" or "imperial"
  termsAgreed: false,
};


const settingReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SETTING_ITEM: {
      const { name, value } = action.payload;
      // const settings = updateObjectInArray(state.settings, { index: id, item: value });
      // console.log(settings, id, value);
      return {
        ...state,
        [name]: value
      }
    }
    default:
      return state;
  }
}

// const updateObjectInArray = (array, action) => {
//   return array.map((item, index) => {
//     if (index !== action.index) {
//       // This isn't the item we care about - keep it as-is
//       return item
//     }

//     let tempObj = Object.assign({}, item);
//     tempObj.value = action.item;
//     // Otherwise, this is the one we want - return an updated value
//     return {
//       ...item,
//       ...tempObj
//     }
//   })
// }

export default settingReducer;