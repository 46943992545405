import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  AppBar,
  Badge,
  BottomNavigation,
  BottomNavigationAction,
  Box,
  CircularProgress,
  Grid,
  GridList,
  IconButton,
  Typography
} from "@material-ui/core";
import { withStyles, withTheme } from "@material-ui/styles";
import RefreshIcon from "@material-ui/icons/Refresh";
import TvTwoTone from "@material-ui/icons/TvTwoTone";
import FavoriteIcon from "@material-ui/icons/Favorite";
import WasdKeyboard from "../../assets/images/wasd_keyboard.png";
import ChannelView from "../containers/channelView";
import PopularView from "../containers/popularView";
import {
  DRAWER_WIDTH,
  NAVBAR_HEIGHT,
  VBREAKUP_SM,
  VBREAKUP_MD
} from "../constants";

import {
  getCategoryList,
  getChannelList,
  getProgramList,
  getVideoList,
  resetMediaURL,
  setTabId
} from "../actions";

class LeftPane extends Component {
  _handleTabClick = (e, tabId) => {
    if (tabId !== this.props.main.tabId) {
      this.props.resetMediaURL();
    }
    this.props.setTabId(tabId);
  };

  _handleRefreshClick = catId => {
    if (this.props.main.tabId === 0) {
      this.props.getCategoryList();
      this.props.getChannelList(null, catId);
      this.props.getProgramList();
    } else {
      this.props.getVideoList();
    }
  };

  render() {
    const { classes, channels, videos } = this.props;
    const { tabId, screenHeight } = this.props.main;
    return (
      <div className={classes.root} onKeyDown={this._handleKeyDown}>
        {screenHeight > VBREAKUP_SM ? (
          <AppBar position="sticky" color="primary" className={classes.appBar}>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
              className={classes.titleContainer}
            >
              <Grid item xs={3}>
                <img
                  src={WasdKeyboard}
                  alt="Navigation Keys"
                  aria-label="Use W A S D keys to navigate"
                  className={classes.wasdImage}
                />
              </Grid>
              <Grid item xs={7}>
                <Typography className={classes.title}>
                  {tabId === 0 ? "Channel List" : "Popular Videos"}
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <IconButton
                  className={classes.refreshIcon}
                  color={"primary"}
                  onClick={(e, categoryId) =>
                    this._handleRefreshClick(channels.categoryId)
                  }
                >
                  <RefreshIcon />
                </IconButton>
              </Grid>
            </Grid>
          </AppBar>
        ) : null}
        <GridList
          className={classes.gridList}
          style={{
            height:
              screenHeight < VBREAKUP_SM
                ? "100vh"
                : `calc(100% - ${NAVBAR_HEIGHT * 2}px)`
          }}
        >
          <Box>
            {channels.loading || videos.loading ? (
              <div className={classes.progressContainer}>
                <CircularProgress
                  className={classes.progress}
                  size={50}
                  color="secondary"
                />
              </div>
            ) : tabId === 0 ? (
              <ChannelView />
            ) : (
              <PopularView />
            )}
          </Box>
        </GridList>
        {screenHeight > VBREAKUP_SM ? (
          <BottomNavigation
            value={tabId}
            onChange={(e, tabId) => this._handleTabClick(e, tabId)}
            showLabels
            className={classes.bottomBar}
          >
            <BottomNavigationAction
              icon={
                <Badge
                  className={classes.badge}
                  color="secondary"
                  badgeContent={channels.channels.length}
                  classes={{ badge: classes.badgePosition }}
                >
                  <TvTwoTone />
                </Badge>
              }
              label={"Channels"}
            />
            <BottomNavigationAction
              icon={
                <Badge
                  className={classes.badge}
                  color="secondary"
                  badgeContent={videos.videos.length}
                  classes={{ badge: classes.badgePosition }}
                >
                  <FavoriteIcon />
                </Badge>
              }
              label={"Popular"}
            />
          </BottomNavigation>
        ) : null}
      </div>
    );
  }
}

LeftPane.propType = {
  classes: PropTypes.object.isRequired
};

const styles = theme => ({
  root: {
    height: "100vh",
    width: DRAWER_WIDTH,
    overflowY: "hidden",
    overflowX: "hidden"
  },
  appBar: {
    backgroundColor: theme.palette.background.dark,
    color: theme.palette.primary.light,
    width: DRAWER_WIDTH
  },
  titleContainer: {
    width: "100%",
    height: NAVBAR_HEIGHT,
    backgroundColor: theme.palette.background.dark,
    marginTop: theme.spacing(0),
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(4)
    // justifyContent: "center",
    // alignItems: "center"
    // width: DRAWER_WIDTH
  },
  title: {
    // padding: theme.spacing(2),
    color: theme.palette.primary.main,
    textAlign: "flex-start",
    fontSize: 20,
    fontWeight: "bold"
  },
  refreshIcon: {
    marginLeft: theme.spacing(0),
    paddingLeft: theme.spacing(0),
    marginRight: theme.spacing(3)
  },
  bottomBar: {
    bottom: 0,
    width: DRAWER_WIDTH,
    height: NAVBAR_HEIGHT,
    backgroundColor: theme.palette.background.dark,
    position: "fixed",
    margin: "auto"
  },
  gridList: {
    // width: DRAWER_WIDTH,
    // position: "absolute",
    height: `calc(100% - ${NAVBAR_HEIGHT * 2}px)`,
    width: "100%",
    overflowY: "auto",
    overflowX: "hidden",
    backgroundColor: theme.palette.background.light
    // padding: `theme.spacing(0) theme.spacing(1)`,
    // justifyContent: "center"
    // paddingLeft: theme.spacing(1),
    // paddingRight: theme.spacing(0)
    // marginLeft: theme.spacing(3)
  },
  progressContainer: {
    justifyContent: "center",
    alignItems: "center"
  },
  progress: {
    margin: theme.spacing(14)
  },
  badge: {
    padding: `0 ${theme.spacing(1.5)}px`
  },
  badgePosition: {
    top: "25%"
  },
  pullableContainer: {
    justifyContent: "center",
    padding: 0,
    margin: 0,
    width: "100%"
  },
  wasdImage: {
    width: 52,
    height: 34,
    marginLeft: theme.spacing(1),
    padding: theme.spacing(0)
  }
  // pulldownSpinner: {
  //   justifyContent: "center"
  // }
});

const mapStateToProps = state => {
  return {
    main: state.main,
    channels: state.channels,
    categories: state.categories,
    videos: state.videos
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getChannelList: (callback, catId) =>
      dispatch(getChannelList(callback, catId)),
    getProgramList: silentRun => dispatch(getProgramList(silentRun)),
    getVideoList: () => dispatch(getVideoList()),
    getCategoryList: () => dispatch(getCategoryList()),
    resetMediaURL: () => dispatch(resetMediaURL()),
    setTabId: tabId => dispatch(setTabId(tabId))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(LeftPane));
