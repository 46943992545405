import React, { Component } from "react";
import PropTypes from "prop-types";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { classNames } from "classnames";
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Checkbox,
  Input,
  InputLabel,
  Paper,
  SnackbarContent,
  Typography
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import ErrorIcon from "@material-ui/icons/Error";
import { handleInput, startLogin, startLogout } from "../actions";

// import SocialLogin from './SocialLogin';

class LoginView extends Component {
  _handleChange = evt => {
    const target = evt.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    this.props.handleInput(target.name, value);
  };

  _handleSubmit = evt => {
    evt.preventDefault();
    this.props.startLogin();
  };

  render() {
    const { username, password, rememberMe, loading } = this.props.auth;
    const { classes, history } = this.props;
    return (
      <main className={classes.main}>
        <Paper className={classes.paper} elevation={2}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Login
          </Typography>
          {/* {!!error && (
            <SnackbarContent
              className={classes.error}
              message={
                <span id="client-snackbar" className={classes.message}>
                  <Box display="flex" justifyContent="space-between">
                    <Box mr={2}>
                      <ErrorIcon />
                    </Box>
                    {error}
                  </Box>
                </span>
              }
            />
          )} */}
          {/* <SocialLogin
            handleSocialLogin={handleSocialLogin}
            handleSocialLoginFailure={handleSocialLoginFailure}
          /> */}
          <form
            className={classes.form}
            onSubmit={e => this._handleSubmit(e)}
            method="post"
          >
            <FormControl margin="normal" required fullWidth>
              <InputLabel htmlFor="email">User Name</InputLabel>
              <Input
                name="username"
                id="username"
                // placeholder="user name"
                autoFocus
                value={username}
                onChange={this._handleChange}
              />
            </FormControl>
            <FormControl margin="normal" required fullWidth>
              <InputLabel htmlFor="password">Password</InputLabel>
              <Input
                name="password"
                type="password"
                id="password"
                // placeholder="password"
                value={password}
                onChange={this._handleChange}
              />
            </FormControl>
            <br />
            <FormControlLabel
              control={
                <Checkbox
                  name="rememberMe"
                  value="rememberMe"
                  color="primary"
                  // checked={rememberMe}
                  defaultChecked={rememberMe}
                />
              }
              label="Remember me"
              onChange={this._handleChange}
            />
            <Box display="flex">
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.button}
                // disabled={!isPermitted}
              >
                {loading ? (
                  <CircularProgress
                    className={classes.progress}
                    color="secondary"
                    size={24}
                  />
                ) : (
                  <Typography>Sign In</Typography>
                )}
              </Button>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                className={classes.button}
                onClick={() => history.push("/")}
              >
                Back
              </Button>
            </Box>
          </form>
          <br />
        </Paper>
      </main>
    );
  }
}

const styles = theme => ({
  main: {
    width: "auto",
    position: "relative",
    display: "block", // Fix IE 11 issue.
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(400 + theme.spacing(3 * 2))]: {
      width: 400,
      marginLeft: "auto",
      marginRight: "auto"
    }
  },
  paper: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(
      3
    )}px`,
    // backgroundImage: `url(${adminMenuBG})`,
    backgroundSize: "cover"
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  button: {
    margin: theme.spacing(1),
    marginTop: theme.spacing(3)
  },
  snackbar: {
    margin: theme.spacing(1)
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(2)
  },
  error: {
    backgroundColor: theme.palette.error.dark,
    color: theme.palette.text.default,
    margin: theme.spacing(1)
  },
  progress: {
    margin: `${theme.spacing(1)} ${theme.spacing(2)}`
  }
});

const mapStateToProps = state => {
  return {
    auth: state.auth
  };
};

const mapDispatchToProps = dispatch => {
  return {
    handleInput: (inputField, value) =>
      dispatch(handleInput(inputField, value)),
    startLogin: () => dispatch(startLogin()),
    startLogout: () => dispatch(startLogout())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  compose(
    withRouter,
    withStyles(styles)
  )(LoginView)
);
