import React, { Component } from "react";
import { connect } from "react-redux";
import { Grid, AppBar, Tabs, Tab } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import {
  getChannelList,
  setTabId,
  toggleDrawer,
  toggleVideoTab
} from "../actions";
import { NAVBAR_HEIGHT, DRAWER_WIDTH } from "../constants";

class Footer extends Component {
  state = {
    catId: 0
  };

  _handleCategoryClick = catId => {
    console.log(catId);
    this.setState({
      catId
    });
    this.props.setTabId(0);
    this.props.handleCategoryClick(this._callback, catId);
  };

  _callback = () => {
    if (this.props.main.screenWidth < 960) {
      setTimeout(() => this.props.toggleDrawer(true), 300);
    }
  };

  render() {
    const { classes } = this.props;
    const { categories } = this.props.categories;
    const { catId } = this.state;
    return (
      <Grid container>
        <Grid item>
          <AppBar position="static" className={classes.stickToBottom}>
            <Tabs
              value={catId}
              onChange={(e, catId) => this._handleCategoryClick(catId)}
              indicatorColor="secondary"
              textColor="primary"
              variant="scrollable"
              scrollButtons="on"
              className={classes.scroller}
              classes={{
                indicator: classes.indicator
              }}
            >
              <Tab
                // className={classes.tab}
                key={0}
                value={0}
                label="All Channels"
              />
              {categories.map((category, index) => {
                index++;
                return (
                  <Tab
                    // classes={{ root: classes.root }}
                    key={index}
                    value={category.id}
                    label={category.category}
                  />
                );
              })}
            </Tabs>
          </AppBar>
        </Grid>
      </Grid>
    );
  }
}

const styles = theme => ({
  scroller: {
    color: theme.palette.text.secondary
  },
  stickToBottom: {
    color: theme.palette.primary,
    backgroundColor: theme.palette.background.dark,
    position: "fixed",
    bottom: 0,
    margin: theme.spacing(0),
    // paddingTop: theme.spacing(0.2),
    // paddingBottom: 0,
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(0),
    [theme.breakpoints.down("md")]: {
      left: 0,
      width: "100%"
    },
    [theme.breakpoints.up("md")]: {
      left: DRAWER_WIDTH,
      width: `calc(100% - ${DRAWER_WIDTH}px)`
    },
    height: NAVBAR_HEIGHT
  },
  root: {
    // width: theme.spacing(4)
  },
  indicator: {
    height: theme.spacing(0.5)
    // width: theme.spacing(5)
  }
});

const mapStateToProps = state => {
  const { main, channels, categories } = state;
  return {
    main,
    channels,
    categories
  };
};

const mapDispatchToProps = dispatch => {
  return {
    handleCategoryClick: (callback, catId) =>
      dispatch(getChannelList(callback, catId)),
    setTabId: tabId => dispatch(setTabId(tabId)),
    toggleDrawer: isOpen => dispatch(toggleDrawer(isOpen)),
    toggleVideoTab: isVisible => dispatch(toggleVideoTab(isVisible))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Footer));
