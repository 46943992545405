import React, { Component } from "react";
import AdSense from "react-adsense";
import { Grid } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import { ADMOB_BANNER_ADUNITID } from "../constants";

class AdSenseWrapper extends Component {
  render() {
    const { classes } = this.props;
    const adSenseId = ADMOB_BANNER_ADUNITID.split("/");
    return (
      <Grid className={classes.root}>
        {/* auto full width responsive ads */}
        <AdSense.Google
          client={adSenseId[0]}
          slot={adSenseId[1]}
          style={{ display: "block" }}
          format="auto"
          responsive="true"
          layoutKey="-gw-1+2a-9x+5c"
        />
      </Grid>
    );
  }
}

const styles = theme => ({
  root: {
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5)
  }
});

export default withStyles(styles)(AdSenseWrapper);
