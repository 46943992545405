export const SET_ORIENTATION = "set_orientation";
export const SET_SCREEN_DIMENSION = "set_screen_dimension";
export const SET_VERSION = "set_version";
export const SET_TAB_ID = "set_tab_id";
export const TOGGLE_FULLSCREEN = "toggle_fullscreen";
export const TOGGLE_DRAWER = "toggle_drawer";

export const GET_SERVICE_INFO = "get_service_info";
export const SET_SERVICE_INFO = "set_service_info";
export const GET_SERVICE_INFO_SUCCESS = "get_service_info_success";
export const GET_SERVICE_INFO_FAILURE = "get_service_info_failure";

export const GET_CHANNEL_LIST = "get_channel_list";
export const GET_CHANNEL_LIST_SUCCESS = "get_channel_list_success";
export const GET_CHANNEL_LIST_FAILURE = "get_channel_list_failure";
export const RESET_CHANNEL_ERROR = "reset_channel_error";
export const RESET_UPDATE_MESSAGE = "reset_update_message";
export const RESET_CHANNEL_MESSAGE = "reset_channel_message";
export const START_CHANNEL_UPDATE = "start_channel_update";
export const CHANNEL_UPDATE_SUCCESS = "channel_update_success";
export const CHANNEL_UPDATE_FAILURE = "channel_update_failure";

export const GET_CATEGORY_LIST = "get_category_list";
export const GET_CATEGORY_LIST_SUCCESS = "get_category_list_success";
export const GET_CATEGORY_LIST_FAILURE = "get_category_list_failure";

export const TOGGLE_TVGUIDE = "toggle_tvguide";
export const SET_CHANNEL_NAME = "set_channel_name";
export const SET_CHANNEL_IDX = "set_current_channel_idx";

export const GET_PROGRAM_LIST = "get_program_list";
export const GET_PROGRAM_LIST_SUCCESS = "get_program_list_success";
export const GET_PROGRAM_LIST_FAILURE = "get_program_list_failure";
export const SET_PROGRAM_DETAIL = "set_program_detail";
export const START_PROGRAM_UPDATE = "start_program_update";
export const PROGRAM_UPDATE_SUCCESS = "program_update_success";
export const PROGRAM_UPDATE_FAILURE = "program_update_failure";
export const RESET_PROGRAM_MESSAGE = "reset_program_message";

export const GET_VIDEO_LIST = "get_video_list";
export const GET_VIDEO_LIST_SUCCESS = "get_video_list_success";
export const GET_VIDEO_LIST_FAILURE = "get_video_list_failure;";
export const SET_VIDEO_URL = "set_video_url";
export const COMPLETE_VIDEO_LOADING = "complete_video_loading";
export const TOGGLE_VIDEO_TAB = "toggle_video_tab";

export const SET_CATEGORY = "set_category";
export const SET_MEDIA_URL = "set_media_url";
export const RESET_MEDIA_URL = "reset_media_url";
export const SET_CHANNEL_URL = "set_channel_url";
export const HANDLE_WEBVIEW_PRESS = "handle_webview_press";
export const SET_HLS_EXT_REQUIRED = "set_hls_ext_required";

export const HANDLE_VIDEO_PLAY = "handle_video_play";
export const HANDLE_VIDEO_ERROR = "handle_video_error";
export const RESET_VIDEO_ERROR = "reset_video_error";

export const COMPLETE_LOADING = "complete_loading";
export const UPDATE_PROGRAM = "update_program";
export const SET_CURRENT_CHANNEL = "set_current_channel";

export const START_LOGIN = "start_login";
export const LOGIN_SUCCESS = "login_success";
export const LOGIN_FAILURE = "login_failure";

export const START_LOGOUT = "start_logout";
export const LOGOUT_SUCCESS = "logout_success";
export const LOGOUT_FAILURE = "logout_failure";

export const START_PASSWORD_CHANGE = "start_password_change";
export const PASSWORD_CHANGE_SUCCESS = "password_change_success";
export const PASSWORD_CHANGE_FAILURE = "password_change_failure";
export const RESET_LOGIN_ERROR = "reset_login_error";
export const RESET_ALL_PASSWORDS = "reset_all_passwords";
export const RESET_AUTH_MESSAGE = "rest_auth_message";

export const HANDLE_TEXT_INPUT = "handle_text_input";

export const GET_SETTING_LIST = "get_setting_list";
export const SET_SETTING_ITEM = "set_setting_item";

export const GET_WEATHER_INFO = "get_weather_info";
export const GET_WEATHER_INFO_SUCCESS = "get_weather_info_success";
export const GET_WEATHER_INFO_FAILURE = "get_weather_info_failure";
export const SET_FORECAST_INFO = "set_forecast_info";
export const SET_CITY_LIST = "set_city_list";
export const ADD_CITY = "add_city";
export const REMOVE_CITY = "remove_city";
export const REORDER_CITY = "reorder_city";
export const SET_BATTERY_INFO = "set_battery_info";
export const GET_DATETIME_INFO = "get_datetime_info";
export const SHOW_CLOCK = "show_clock";
export const GET_GEO_LOCATION = "get_geo_location";
export const SET_CELLULAR_PERMISSION = "set_cellular_permission";
export const SET_NETWORK_TYPE = "set_network_type";
