import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  AppBar,
  Box,
  ClickAwayListener,
  Grow,
  IconButton,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Toolbar,
  Typography
} from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import Clock from "react-live-clock";
import MenuIcon from "@material-ui/icons/Menu";
import MoreVert from "@material-ui/icons/MoreVert";
import Logo from "../../assets/images/TV.png";
import { startLogout, resetAuthMessage, toggleDrawer } from "../actions";
import {
  DRAWER_WIDTH,
  NAVBAR_HEIGHT,
  HOMEPAGE_URL,
  SUPPORT_EMAIL_ADDRESS
} from "../constants";

class Header extends Component {
  state = {
    menuOpen: false
  };

  _handleClose = () => {
    this.setState({ menuOpen: false });
  };

  _handleLogout = () => {
    this.props.startLogout();
    this._handleClose();
  };

  render() {
    const { classes, main } = this.props;
    const { isAuthenticated, error, message } = this.props.auth;
    return (
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar justify="space-between">
          <IconButton
            color="inherit"
            aria-label="Open side drawer"
            edge="start"
            onClick={() => this.props.toggleDrawer(!main.showDrawer)}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <a target="_blank" href={`${HOMEPAGE_URL}`}>
            <img src={Logo} alt="logo" className={classes.logoImage} />
          </a>
          <Typography variant="h6" className={classes.title}>
            <a
              target="_blank"
              href={`${HOMEPAGE_URL}`}
              className={classes.link}
            >
              myLiveTV
            </a>
          </Typography>
          <div>
            <Clock
              format={"HH : mm"}
              ticking={true}
              // timezone={"Asia/Seoul"}
              className={classes.appClock}
            />
            <IconButton
              buttonRef={node => {
                this.anchorEl = node;
              }}
              color="inherit"
              aria-owns="login menu"
              aria-haspopup="true"
              edge="end"
              onClick={() => {
                this.setState({ menuOpen: true });
              }}
              className={classes.moreButton}
            >
              <MoreVert />
            </IconButton>
            <Popper
              open={this.state.menuOpen}
              anchorEl={this.anchorEl}
              transition
              disablePortal
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  id="menu-list-grow"
                  style={{
                    transformOrigin:
                      placement === "bottom" ? "center top" : "center bottom"
                  }}
                >
                  <Paper className={classes.popupMenu}>
                    <ClickAwayListener onClickAway={this._handleClose}>
                      <MenuList>
                        <a
                          href={`mailto:${SUPPORT_EMAIL_ADDRESS}`}
                          className={classes.menuLink}
                        >
                          <MenuItem
                            disabled={false}
                            onClick={this._handleClose}
                          >
                            Contact Us
                          </MenuItem>
                        </a>
                        {isAuthenticated ? (
                          <Box>
                            <Link to="/auth" className={classes.menuLink}>
                              <MenuItem onClick={this._handleClose}>
                                Admin Menu
                              </MenuItem>
                            </Link>
                            <Link to="/" className={classes.menuLink}>
                              <MenuItem onClick={this._handleLogout}>
                                Logout
                              </MenuItem>
                            </Link>
                          </Box>
                        ) : (
                          <Link to="/auth" className={classes.menuLink}>
                            <MenuItem onClick={this._handleClose}>
                              Login
                            </MenuItem>
                          </Link>
                        )}
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </div>
        </Toolbar>
      </AppBar>
    );
  }
}

const styles = theme => ({
  appBar: {
    backgroundColor: theme.palette.background.dark,
    color: theme.palette.primary.light,
    marginLeft: DRAWER_WIDTH,
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - ${DRAWER_WIDTH}px)`
    },
    height: NAVBAR_HEIGHT,
    justifyContent: "center"
  },
  appClock: {
    color: "white",
    textShadow: "1px 1px 5px black, 0 0 10px #ffbb00, 0 0 8px white",
    fontWeight: "bold",
    fontFamily: "Orbitron",
    fontSize: 16,
    paddingRight: theme.spacing(1)
    // margin: "auto"
  },
  menuButton: {
    color: theme.palette.primary.main,
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      display: "none"
    }
  },
  popupMenu: {
    width: theme.spacing(22)
  },
  logoImage: {
    width: 28,
    height: 32,
    marginRight: 10,
    paddingBottom: 4
  },
  toolbar: theme.mixins.toolbar,
  title: {
    fontWeight: "bold",
    flexGrow: 1
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: "none"
  },
  menuLink: {
    color: theme.palette.text.primary,
    textDecoration: "none"
  },
  chromeCast: {
    width: 32,
    height: 24
  }
});

const mapStateToProps = state => {
  return {
    auth: state.auth,
    main: state.main
  };
};

const mapDispatchToProps = dispatch => {
  return {
    toggleDrawer: isOpen => dispatch(toggleDrawer(isOpen)),
    startLogout: () => dispatch(startLogout()),
    resetAuthMessage: () => dispatch(resetAuthMessage())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Header));
