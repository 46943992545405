import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { compose } from "recompose";
import {
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  List,
  ListItem,
  Paper,
  Typography
} from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";
import { withStyles } from "@material-ui/styles/";
import adminMenuBG from "../../assets/images/AdminMenuBG.jpg";
import {
  resetChannelMessage,
  resetProgramMessage,
  startLogout,
  updateChannelLink,
  updateProgramList
} from "../actions";
// import SnackbarWrapper from "../common/snackbar";

const getGreet = () => {
  const myDate = new Date();
  const hrs = myDate.getHours();
  let greet;

  if (hrs < 12) greet = "Good Morning";
  else if (hrs >= 12 && hrs <= 17) greet = "Good Afternoon";
  else if (hrs >= 17 && hrs <= 24) greet = "Good Evening";
  return greet;
};

class AdminMenuView extends Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
  }

  componentDidMount() {
    this.formRef.current.focus();
  }

  _handleLogout = () => {
    this.props.startLogout();
  };

  _handleUpdateChannel = () => {
    this.props.updateChannelLink();
  };

  _handleUpdateProgram = () => {
    this.props.updateProgramList();
  };

  _resetMessage = () => {
    console.log("_resetMessage was called");
    this.props.resetChannelMessage();
    this.props.resetProgramMessage();
  };

  render() {
    // const { classes, username, token, handleUpdateChannel, handleForgetMe, handleLogout } = this.props;
    const { classes, history } = this.props;
    const { username, userPrivilege } = this.props.auth;
    const { channels, programs } = this.props;
    // const variant = channels.error || programs.error ? "error" : "success";
    // const showSnackbar =
    //   !!channels.error ||
    //   !!channels.message ||
    //   !!programs.error ||
    //   !!programs.message;
    // const snackbarMessage =
    //   channels.error || channels.message || programs.error || programs.message;
    return (
      <div className={classes.main}>
        <Paper className={classes.paper} elevation={2}>
          <FormControl>
            <List>
              <ListItem className={classes.listitem}>
                <Typography variant="h5" component="h5">
                  {getGreet()}{" "}
                  {!!username &&
                    `, ${username.charAt(0).toUpperCase() + username.slice(1)}`}
                </Typography>
              </ListItem>
              <Divider component="li" className={classes.divider} />
              {/* <FormControlLabel
                control={<Checkbox value="forget" color="primary" />}
                label="Forget me"
              // onChange={handleForgetMe}
              />
              <Divider component="li" /> */}
              <ListItem className={classes.listitem}>
                <Button
                  ref={this.formRef}
                  variant="contained"
                  color="secondary"
                  className={classes.button}
                  onClick={() => history.push("/")}
                >
                  Back To Main
                </Button>
              </ListItem>
              {userPrivilege === "superuser" ? (
                <ListItem className={classes.listitem}>
                  <Button
                    variant="contained"
                    color="secondary"
                    className={classes.button}
                    onClick={this._handleUpdateChannel}
                    // disabled={userPrivilege === "superuser" ? false : true}
                  >
                    {channels.loadingUpdate ? (
                      <CircularProgress color="primary" size={24} />
                    ) : (
                      <Typography>Update Channels</Typography>
                    )}
                  </Button>
                </ListItem>
              ) : null}
              {userPrivilege === "superuser" ? (
                <ListItem className={classes.listitem}>
                  <Button
                    variant="contained"
                    color="secondary"
                    className={classes.button}
                    onClick={this._handleUpdateProgram}
                    // disabled={userPrivilege === "superuser" ? false : true}
                  >
                    {programs.loadingUpdate ? (
                      <CircularProgress color="primary" size={24} />
                    ) : (
                      <Typography>Update Programs</Typography>
                    )}
                  </Button>
                </ListItem>
              ) : null}

              <ListItem className={classes.listitem}>
                <Button
                  variant="contained"
                  color="secondary"
                  className={classes.button}
                  onClick={() => history.push("/change_password")}
                >
                  <Typography>Change Password</Typography>
                </Button>
              </ListItem>
              <ListItem className={classes.listitem}>
                <Button
                  variant="contained"
                  color="secondary"
                  className={classes.button}
                  onClick={this._handleLogout}
                >
                  {userPrivilege === "superuser" ? "Admin Logout" : "Logout"}
                </Button>
              </ListItem>
            </List>
          </FormControl>
        </Paper>
      </div>
    );
  }
}

AdminMenuView.propTypes = {
  classes: PropTypes.object.isRequired
};

const styles = theme => ({
  main: {
    width: "auto",
    position: "relative",
    display: "block", // Fix IE 11 issue.
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(400 + theme.spacing(3 * 2))]: {
      width: 400,
      marginLeft: "auto",
      marginRight: "auto"
    }
  },
  paper: {
    ...theme.mixins.gutters(),
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(
      3
    )}px`,
    // backgroundImage: `url(${adminMenuBG})`,
    backgroundSize: "cover"
  },
  button: {
    margin: theme.spacing(1),
    width: theme.spacing(30),
    height: theme.spacing(5)
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  listitem: {
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5)
  },
  menuLink: {
    color: theme.palette.text.primary,
    textDecoration: "none"
  }
});

const mapStateToProps = state => {
  return {
    auth: state.auth,
    main: state.main,
    channels: state.channels,
    programs: state.programs
  };
};

const mapDispatchToProps = dispatch => {
  return {
    startLogout: () => dispatch(startLogout()),
    updateChannelLink: () => dispatch(updateChannelLink()),
    updateProgramList: () => dispatch(updateProgramList()),
    resetChannelMessage: () => dispatch(resetChannelMessage()),
    resetProgramMessage: () => dispatch(resetProgramMessage())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  compose(
    withRouter,
    withStyles(styles)
  )(AdminMenuView)
);
