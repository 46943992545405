import {
  GET_CATEGORY_LIST,
  GET_CATEGORY_LIST_SUCCESS,
  GET_CATEGORY_LIST_FAILURE
} from '../constants';


const initialState = {
  loading: false,
  categories: [],
  error: null,
}

export default function categoryReducer(state = initialState, action) {
  switch (action.type) {
    case GET_CATEGORY_LIST:
      return {
        ...state,
        loading: true,
        error: null
      };
    case GET_CATEGORY_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        categories: action.payload,
        error: null
      };
    case GET_CATEGORY_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    default:
      return state;
  }
}