import {
  GET_SERVICE_INFO,
  SET_SERVICE_INFO,
  GET_SERVICE_INFO_SUCCESS,
  GET_SERVICE_INFO_FAILURE,
} from '../constants';

const initialState = {
  loading: false,
  terminate: false,
  adBanner: true,
  adInterstitial: true,
  adThreshold: 5,
  title: "",
  message: "",
  duration: 0,
  timePublished: null,
  maintenance: false,
  timeScheduled: null,
  noticed: false,
  error: null,
}

export default function serviceReducer(state = initialState, action) {
  switch (action.type) {
    case GET_SERVICE_INFO:
      return {
        ...state,
        loading: true,
        error: null
      };
    case GET_SERVICE_INFO_SUCCESS:
      const {
        terminate,
        adBanner,
        adInterstitial,
        adThreshold,
        title,
        message,
        duration,
        timePublished,
        maintenance,
        timeScheduled
      } = action.payload;
      let noticed = state.noticed;
      if (state.noticed && state.timePublished !== timePublished) {
        noticed = false;
      }
      return {
        ...state,
        loading: false,
        terminate,
        adBanner,
        adInterstitial,
        adThreshold,
        title,
        message,
        duration,
        timePublished,
        maintenance,
        timeScheduled,
        noticed,
        error: null,
      };
    case GET_SERVICE_INFO_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case SET_SERVICE_INFO:
      return {
        ...state,
        noticed: true
      }
    default:
      return state;
  }
}