import {
  GET_PROGRAM_LIST,
  GET_PROGRAM_LIST_SUCCESS,
  GET_PROGRAM_LIST_FAILURE,
  SET_PROGRAM_DETAIL,
  START_PROGRAM_UPDATE,
  PROGRAM_UPDATE_SUCCESS,
  PROGRAM_UPDATE_FAILURE,
  RESET_PROGRAM_MESSAGE
} from "../constants";

const initialState = {
  loading: false,
  loadingUpdate: false,
  programs: [],
  error: null,
  message: null,
  currentProgram: "",
  nextProgram: "",
  startTime: "",
  finishTime: "",
  lastCheckedTime: null
};

const compareChannels = (channel1, channel2) => {
  if (!channel1 || !channel2) {
    return -1;
  }
  return (
    channel1.replace(/\s+/g, "").toUpperCase() ===
    channel2.replace(/\s+/g, "").toUpperCase()
  );
};

export default function programReducer(state = initialState, action) {
  let idx = -1;
  let currentProgram = "";
  let nextProgram = "";
  let startTime = "";
  let finishTime = "";

  switch (action.type) {
    case GET_PROGRAM_LIST:
      return {
        ...state,
        loading: action.payload,
        programs: action.payload ? [...state.programs] : [],
        lastCheckedTime: Date.now()
      };
    case GET_PROGRAM_LIST_SUCCESS: {
      const { programs, currentChannel } = action.payload;
      if (!!programs && !!currentChannel) {
        idx = programs.findIndex(program =>
          compareChannels(program.channel, currentChannel)
        );
        if (idx > -1) {
          currentProgram = programs[idx].program;
          nextProgram = programs[idx].next;
          startTime = programs[idx].from;
          finishTime = programs[idx].till;
        }
      }
      return {
        ...state,
        loading: false,
        programs,
        error: null,
        message: null,
        currentProgram,
        nextProgram,
        startTime,
        finishTime
        // lastCheckedTime: Date.now()
      };
    }
    case GET_PROGRAM_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
        // lastCheckedTime: Date.now()
      };
    case SET_PROGRAM_DETAIL:
      idx = state.programs.findIndex(program =>
        compareChannels(program.channel, action.payload)
      );
      if (idx > -1) {
        currentProgram = state.programs[idx].program;
        nextProgram = state.programs[idx].next;
        startTime = state.programs[idx].from;
        finishTime = state.programs[idx].till;
      }
      return {
        ...state,
        currentProgram,
        nextProgram,
        startTime,
        finishTime
      };
    case START_PROGRAM_UPDATE:
      return {
        ...state,
        loadingUpdate: true
      };
    case PROGRAM_UPDATE_SUCCESS:
      return {
        ...state,
        loadingUpdate: false,
        message: action.payload
      };
    case PROGRAM_UPDATE_FAILURE:
      return {
        ...state,
        loadingUpdate: false,
        error: action.payload
      };
    case RESET_PROGRAM_MESSAGE:
      return {
        ...state,
        error: null,
        message: null
      };
    default:
      return state;
  }
}
