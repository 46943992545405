import React from "react";

export default function withKeyboard(Component) {
  return class extends React.Component {
    state = {
      keyCode: null
    };

    componentDidMount() {
      document.addEventListener("keydown", this._handleKeyDown);
    }

    componentWillUnmount() {
      document.removeEventListener("keydown", this._handleKeyDown);
    }

    _handleKeyDown = e => {
      // e.preventDefault();
      if (e.keyCode === 32) {
        e.preventDefault();
      }
      this.setState({ keyCode: e.keyCode });
    };

    render() {
      return (
        <div>
          <Component
            keyCode={this.state.keyCode}
            {...this.props}
            onKeyDown={this._handleKeyDown}
          />
        </div>
      );
    }
  };
}
