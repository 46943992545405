import {
  GET_CHANNEL_LIST,
  GET_CHANNEL_LIST_SUCCESS,
  GET_CHANNEL_LIST_FAILURE,
  RESET_CHANNEL_ERROR,
  RESET_UPDATE_MESSAGE,
  RESET_CHANNEL_MESSAGE,
  START_CHANNEL_UPDATE,
  CHANNEL_UPDATE_SUCCESS,
  CHANNEL_UPDATE_FAILURE
} from "../constants";

const initialState = {
  loading: false,
  loadingUpdate: false,
  isLoaded: false,
  channels: [],
  error: null,
  categoryId: 0,
  updateChannel: false,
  message: null
};

export default function channelReducer(state = initialState, action) {
  switch (action.type) {
    case GET_CHANNEL_LIST:
      return {
        ...state,
        loading: true,
        isLoaded: false,
        error: null
      };
    case GET_CHANNEL_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        isLoaded: true,
        error: null,
        categoryId: action.payload.catId,
        channels: action.payload.channels
      };
    case GET_CHANNEL_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        isLoaded: false,
        error: action.payload
      };
    case RESET_CHANNEL_ERROR:
      return {
        ...state,
        error: null
      };
    case RESET_UPDATE_MESSAGE:
      return {
        ...state,
        message: null
      };
    case RESET_CHANNEL_MESSAGE:
      return {
        ...state,
        error: null,
        message: null
      };
    case START_CHANNEL_UPDATE:
      return {
        ...state,
        loadingUpdate: true,
        message: null
      };
    case CHANNEL_UPDATE_SUCCESS:
      return {
        ...state,
        loadingUpdate: false,
        message: action.payload
      };
    case CHANNEL_UPDATE_FAILURE:
      return {
        ...state,
        loadingUpdate: false,
        message: null
      };
    default:
      return state;
  }
}
