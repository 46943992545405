import React, { Component } from "react";
import ReactPlayer from "react-player";
import { compose } from "recompose";
import { isMobile } from "react-device-detect";
import { Box, Grid } from "@material-ui/core";
import { connect } from "react-redux";
import { withStyles, withTheme } from "@material-ui/styles";
import {
  toggleFullscreen,
  toggleTVGuide,
  toggleDrawer,
  handleWebviewPress,
  handleVideoPlay,
  handleVideoError,
  completeLoading,
  completeVideoLoading,
  resetChannelError,
  resetVideoError,
  resetMediaURL,
  resetAuthMessage,
  resetUpdateMessage,
  showClock
} from "../actions";
import {
  NAVBAR_HEIGHT,
  VBREAKUP_SM,
  VBREAKUP_MD,
  VBREAKUP_LG
} from "../constants";
// import FrameImage from "../../assets/images/TV-frame-sm.png";
import withKeyboard from "../hoc/keyboardShortcut";

class VideoPlayer extends Component {
  state = {
    playing: false
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.main.mediaURL !== this.props.main.mediaURL) {
      this.setState({ playing: true });
    }
    if (nextProps.keyCode === 32) {
      this.setState(prevState => {
        return { playing: !prevState.playing };
      });
    }
  }

  _handleOnStart = () => {
    if (!this.props.main.isPlaying) {
      this.props.handleVideoPlay(this.props.main.currentChannelIdx);
      this.setState({ playing: true });
      this.props.toggleDrawer(false);
    }
  };

  _handleOnError = err => {
    console.log("video error", err, new Date());
    // const { currentChannel, currentChannelIdx, isPlaying } = this.props.main;
    // const { alert } = this.props;
    // if (!isPlaying) {
    //   //   // prevent multi alert displays
    //   if (!this.state.errorLoaded) {
    //     this.setState({ errorLoaded: true });
    //     alert.show(ERROR_VIDEO_LOADING(currentChannel, currentChannelIdx + 1), {
    //       timeout: 4000,
    //       type: "error"
    //       // onClose: () => this.setState({ errorLoaded: false })
    //     });
    //     this.props.resetMediaURL();
    //   }
    // }
  };

  _handleOnPlay = () => {
    this.setState({ playing: true });
  };

  _handleOnPause = () => {
    this.setState({ playing: false });
  };

  render() {
    const { mediaURL, screenHeight } = this.props.main;
    const { classes, theme } = this.props;
    // const height = `calc(100vh - ${
    //   NAVBAR_HEIGHT * (screenHeight > VBREAKUP_MD) ? 10 : 8
    // }px)`;

    return (
      <Grid
        item
        className={classes.playerContainer}
        onKeyDown={this._handleKeyDown}
      >
        <ReactPlayer
          className={classes.reactPlayer}
          url={mediaURL}
          controls
          playing={this.state.playing}
          playsinline={true}
          width={"100%"}
          height={"100%"}
          style={{
            backgroundColor: "black",
            borderColor: "black",
            borderWidth: 0,
            paddingBottom:
              screenHeight < VBREAKUP_MD ? theme.spacing(0) : theme.spacing(5)
            // backgroundImage: `url(${PlayImage})`,
            // backgroundPosition: "center",
            // backgroundRepeat: "no-repeat",
            // backgroundSize: 100
          }}
          config={{
            file: {
              attributes: {
                crossOrigin: "true"
              },
              forceHLS: true
              // hlsOptions: {
              //   xhrSetup: function(xhr, url) {
              //     // xhr.withCredentials = true; // send cookies
              //     xhr.setRequestHeader(
              //       "Access-Control-Allow-Headers",
              //       "Content-Type, Accept, application/x-mpegURL"
              //     );
              //     xhr.setRequestHeader("Access-Control-Allow-Origin", "*");
              //     xhr.setRequestHeader(
              //       "Access-Control-Allow-Credentials",
              //       "true"
              //     );
              //   }
              // }
            },
            youtube: {
              playerVars: { controls: 1 }
            }
          }}
          // onBuffer={this._handleOnStart}
          // onPlay={this._handleOnStart}
          onReady={this._handleOnStart}
          onError={err => this._handleOnError(err)}
          onPause={this._handleOnPause}
        />
      </Grid>
    );
  }
}

const styles = theme => ({
  playerContainer: {
    position: "relative",
    paddingTop:
      window.innerHeight > VBREAKUP_LG
        ? "46%"
        : window.innerHeight > VBREAKUP_MD
        ? "43%"
        : window.innerHeight > VBREAKUP_SM
        ? "40%"
        : "37%",
    height: isMobile
      ? `calc(100vh - ${NAVBAR_HEIGHT * 5.5}px)`
      : `calc(100vh - ${NAVBAR_HEIGHT * 3.5}px)`
    // justifyContent: "center",
    // alignItems: "center"
    // backgroundSize: 100
    // paddingBottom: { NAVBAR_HEIGHT }
  },
  reactPlayer: {
    position: "absolute",
    top: 0,
    left: 0,
    padding: theme.spacing(1),
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(0)
    // paddingTop: theme.spacing(0),
    // paddingLeft: theme.spacing(1),
    // paddingRight: theme.spacing(1),
    // paddingBottom: `calc(${NAVBAR_HEIGHT * 0.5}px)`,
  }
});

const mapStateToProps = state => {
  return {
    main: state.main
  };
};

const mapDispatchToProps = dispatch => {
  return {
    handleVideoPlay: idx => dispatch(handleVideoPlay(idx)),
    handleVideoError: (error, channelIdx) =>
      dispatch(handleVideoError(error, channelIdx)),
    toggleDrawer: isOpen => dispatch(toggleDrawer(isOpen)),
    closeTVGuide: isShown => dispatch(toggleTVGuide(isShown)),
    completeLoading: () => dispatch(completeLoading()),
    resetMediaURL: () => dispatch(resetMediaURL()),
    resetChannelError: () => dispatch(resetChannelError()),
    resetVideoError: () => dispatch(resetVideoError()),
    resetUpdateMessage: () => dispatch(resetUpdateMessage()),
    completeVideoLoading: () => dispatch(completeVideoLoading())
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  compose(
    withKeyboard,
    withStyles(styles, { withTheme: true })
  )(VideoPlayer)
);
