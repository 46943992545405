export const ERROR_NETWORK_CONNECTION =
  "Unable to connect to server. Please try again later.\n\n현재 서버에 연결할 수 없습니다. 잠시 후 다시 이용하시기를 바랍니다.";

export const ERROR_VIDEO_LOADING = (channel, idx) => {
  return `Unable to connect to the channel ${channel} - link ${idx}.\n\n${channel} - link ${idx}에 연결할 수 없습니다. 다른 링크나 잠시 후 다시 시도해보십시요`;
};

export const ERROR_NO_TVGUIDE_LINK =
  "Sorry, TV Guide is not available for the selected channel.\n\n선택하신 채널의 방송 편성표를 찾을 수 없습니다.";

export const ERROR_NO_PROGRAM_TITLE = "Program details are not available.";

export const ERROR_NO_PROGRAM_DESCRIPTION = "No program description";
