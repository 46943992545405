import { combineReducers } from "redux";
import mainReducer from "./mainReducer";
import channelReducer from "./channelReducer";
import categoryReducer from "./categoryReducer";
import programReducer from "./programReducer";
import videoReducer from "./videoReducer";
import authReducer from "./authReducer";
import sceneReducer from "./sceneReducer";
import settingReducer from "./settingReducer";
import infoReducer from "./infoReducer";
import serviceReducer from "./serviceReducer";

// import { responsiveStateReducer } from "redux-responsive";
// import { responsiveDrawer } from "material-ui-responsive-drawer";

export default combineReducers({
  main: mainReducer,
  channels: channelReducer,
  categories: categoryReducer,
  programs: programReducer,
  videos: videoReducer,
  auth: authReducer,
  scene: sceneReducer,
  settings: settingReducer,
  info: infoReducer,
  service: serviceReducer
  // browser: responsiveStateReducer,
  // responsiveDrawer: responsiveDrawer
});
