import React from "react";
import ReactDOM from "react-dom";
import { transitions, positions, Provider as AlertProvider } from "react-alert";
// import AlertTemplate from "react-alert-template-basic";
import AlertTemplate from "react-alert-template-oldschool-dark";
import { DEBUG } from "./components/constants";
import App from "./components/app";
import * as serviceWorker from "./serviceWorker";

// optional configuration
const options = {
  // you can also just use 'bottom center'
  position: positions.MIDDLE,
  timeout: 5000,
  offset: "30px",
  // you can also just use 'scale'
  transition: transitions.SCALE,
  containerStyle: {
    zIndex: 9999
  }
};

// if (!DEBUG) {
//   if (!window.console) window.console = {};
//   const methods = ["log", "debug", "warn", "info"];
//   for (let i = 0; i < methods.length; i++) {
//     console[methods[i]] = () => {};
//   }
// }

const Root = () => (
  <AlertProvider template={AlertTemplate} {...options}>
    <App />
  </AlertProvider>
);

ReactDOM.render(<Root />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
