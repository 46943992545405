import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import { classNames } from "classnames";
import {
  Avatar,
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Input,
  InputLabel,
  Paper,
  Typography
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
// import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import { handleInput, startPasswordChange, resetAuthMessage } from "../actions";
// import SnackbarWrapper from "../common/snackbar";

// import SocialLogin from './SocialLogin';

class ChangePasswordView extends Component {
  componentWillReceiveProps(nextProps, nextState) {
    if (!nextProps.auth.message && !!this.props.auth.message) {
      this.props.history.push("/auth");
    }
  }

  _handleChange = evt => {
    const target = evt.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    this.props.handleInput(target.name, value);
  };

  _handleSubmit = evt => {
    evt.preventDefault();
    this.props.startPasswordChange();
  };

  _handleOnClose = () => {
    this.props.resetAuthMessage();
  };

  render() {
    const {
      oldPassword = "",
      password1 = "",
      password2 = "",
      error,
      message
    } = this.props.auth;
    const { classes, history } = this.props;
    return (
      <main className={classes.main}>
        <Paper className={classes.paper} elevation={2}>
          <Box className={classes.title}>
            <Typography component="h1" variant="h5">
              Change Password
            </Typography>
          </Box>
          {/* {(!!error || !!message) && (
            <SnackbarWrapper
              message={error || message}
              variant={!!error ? "error" : "success"}
              onClose={this._handleOnClose}
            />
          )} */}
          <form
            className={classes.form}
            onSubmit={evt => this._handleSubmit(evt)}
            method="post"
          >
            <FormControl margin="normal" required fullWidth>
              <InputLabel htmlFor="password">Old Password</InputLabel>
              <Input
                id="oldPassword"
                name="oldPassword"
                type="password"
                autoComplete="current-password"
                autoFocus
                value={oldPassword}
                onChange={this._handleChange}
              />
            </FormControl>
            <FormControl margin="normal" required fullWidth>
              <InputLabel htmlFor="password">New Password</InputLabel>
              <Input
                id="password1"
                name="password1"
                type="password"
                autoComplete="new-password"
                value={password1}
                onChange={this._handleChange}
              />
            </FormControl>
            <FormControl margin="normal" required fullWidth>
              <InputLabel htmlFor="password">Confirm New Password</InputLabel>
              <Input
                id="password2"
                name="password2"
                type="password"
                autoComplete="new-password"
                value={password2}
                onChange={this._handleChange}
              />
            </FormControl>
            <Box display="flex">
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="secondary"
                className={classes.button}
              >
                Change Password
              </Button>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="secondary"
                className={classes.button}
                onClick={() => history.push("/auth")}
              >
                Back
              </Button>
            </Box>
          </form>
        </Paper>
      </main>
    );
  }
}

const styles = theme => ({
  main: {
    width: "auto",
    position: "relative",
    display: "block", // Fix IE 11 issue.
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(400 + theme.spacing(3 * 2))]: {
      width: 400,
      marginLeft: "auto",
      marginRight: "auto"
    }
  },
  paper: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(
      3
    )}px`,
    // backgroundImage: `url(${adminMenuBG})`,
    backgroundSize: "cover"
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  title: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  button: {
    margin: theme.spacing(1),
    marginTop: theme.spacing(3)
    // margin: `${theme.spacing(3)}px ${theme.spacing(1)}px`
  },
  snackbar: {
    margin: theme.spacing(1)
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(2)
  },
  error: {
    backgroundColor: theme.palette.error.dark,
    color: theme.palette.text.default,
    margin: theme.spacing(1)
  },
  success: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.text.default,
    margin: theme.spacing(1)
  }
});

const mapStateToProps = state => {
  return {
    auth: state.auth
  };
};

const mapDispatchToProps = dispatch => {
  return {
    handleInput: (inputField, value) =>
      dispatch(handleInput(inputField, value)),
    startPasswordChange: () => dispatch(startPasswordChange()),
    resetAuthMessage: () => dispatch(resetAuthMessage())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  compose(
    withRouter,
    withStyles(styles)
  )(ChangePasswordView)
);
