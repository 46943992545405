import { createStore, applyMiddleware, compose } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension/developmentOnly";
// import { responsiveStoreEnhancer } from "redux-responsive";
import rootReducer from "../reducers/index";

const enhancers = [applyMiddleware(thunk)];

const persistConfig = {
  key: "root",
  storage
};

// require("dotenv").config();

const persistedReducer = persistReducer(persistConfig, rootReducer);

console.log("REACT_APP_STAGE : ", process.env.REACT_APP_NODE_ENV);

const composeEnhancers = composeWithDevTools;
// process.env.NODE_ENV !== "production" ? composeWithDevTools : compose;

// const composeEnhancers =
//   typeof window !== "undefined" &&
//   window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
//   process.env.REACT_APP_NODE_ENV !== "production"
//     ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
//     : compose;

const store = createStore(persistedReducer, composeEnhancers(...enhancers));
const persistor = persistStore(store);

export { store, persistor };
