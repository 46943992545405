import axios from 'axios';
import {
  URL_CATEGORY_LIST_API,
  GET_CATEGORY_LIST,
  GET_CATEGORY_LIST_SUCCESS,
  GET_CATEGORY_LIST_FAILURE,
} from '../constants';


export const getCategoryList = () => {

  return dispatch => {
    dispatch(getCategoryListStarted());

    axios.get(URL_CATEGORY_LIST_API)
      .then(res => {
        dispatch(getCategoryListSuccess(res.data));
      })
      .catch(err => {
        dispatch(getCategoryListFailure(err.message));
      });
  };
}

const getCategoryListStarted = () => ({
  type: GET_CATEGORY_LIST,
});

const getCategoryListSuccess = categories => ({
  type: GET_CATEGORY_LIST_SUCCESS,
  payload: [
    ...categories
  ]
});

const getCategoryListFailure = error => ({
  type: GET_CATEGORY_LIST_FAILURE,
  payload: error
});
