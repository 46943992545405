import React, { Component } from "react";
import PropTypes from "prop-types";
import { compose } from "recompose";
import { withStyles } from "@material-ui/styles";
import { Drawer, Hidden, SwipeableDrawer } from "@material-ui/core";
import { connect } from "react-redux";
import { isMobile } from "react-device-detect";
import {
  getCategoryList,
  getChannelList,
  getProgramList,
  getVideoList,
  toggleFullscreen,
  toggleTVGuide,
  toggleDrawer,
  completeLoading,
  showClock,
  setScreenDimension,
  setOrientation,
  setTabId
} from "../actions";
import {
  DRAWER_WIDTH,
  NAVBAR_HEIGHT,
  VBREAKUP_SM,
  VBREAKUP_MD,
  ADMOB_BANNER_ADUNITID,
  ADMOB_INTERSTITIAL_ADUNITID
} from "../constants";
import LeftPane from "../layout/leftPane";
import Body from "../layout/body";
import Header from "../layout/header";
import AlertView from "../containers/alertView";
// import KeyboardShortcut from "../hoc/keyboardShortcut";

class MainView extends Component {
  componentDidMount() {
    this._updateWindowDimensions();
    this.props.setOrientation(this._getOrientation());
    window.addEventListener("resize", this._updateWindowDimensions);
    window.addEventListener("orientationchange", this._updateOrientation);
    if (this.props.main.orientation === "PORTRAIT" && isMobile) {
      console.log("isMobile", isMobile);
      setTimeout(() => this.props.toggleDrawer(true), 500);
    }
    this.props.getCategoryList();
    this.props.getChannelList();
    this.props.getProgramList();
    this.props.getVideoList();
    this.programIntId = setInterval(
      () => this.props.getProgramList(true),
      6 * 60 * 1000
    );
    this.props.setTabId(0);
  }

  componentWillUnmount() {
    clearInterval(this.programIntId);
    window.removeEventListener("resize", this._updateWindowDimensions);
    window.removeEventListener("orientationchange", this._udpateOrientation);
  }

  _getOrientation = () => {
    const orientation =
      [90, -90].indexOf(window.orientation) > -1 ? "LANDSCAPE" : "PORTRAIT";
    return orientation;
  };

  _updateWindowDimensions = () => {
    this.props.setScreenDimension(window.innerHeight, window.innerWidth);
  };

  _updateOrientation = () => {
    this.props.setOrientation(this._getOrientation());
  };

  _handleSwipeableDrawer = isOpen => {
    this.props.toggleDrawer(isOpen);
  };

  render() {
    const { classes, main } = this.props;
    return (
      <div className={classes.root}>
        {main.screenHeight > VBREAKUP_SM ? <Header /> : null}
        <AlertView />
        <nav className={classes.drawer} aria-label="channels list">
          {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
          <Hidden mdUp implementation="js">
            <SwipeableDrawer
              anchor="left"
              open={main.showDrawer}
              onOpen={() => this._handleSwipeableDrawer(true)}
              onClose={() => this._handleSwipeableDrawer(false)}
              classes={{
                paper: classes.drawerPaper
              }}
              ModalProps={{
                keepMounted: true // Better open performance on mobile.
              }}
              minFlingVelocity={400}
              hysteresis={0.2}
            >
              <LeftPane />
              {/* {!!main.showDrawer && <LeftPane />} */}
            </SwipeableDrawer>
          </Hidden>
          <Hidden smDown implementation="js">
            <Drawer
              classes={{
                paper: classes.drawerPaper
              }}
              variant="permanent"
              open
            >
              <LeftPane />
            </Drawer>
          </Hidden>
        </nav>
        <main className={classes.content}>
          <Body />
        </main>
      </div>
    );
  }
}

MainView.propTypes = {
  // Injected by the documentation to work in an iframe.
  // You won't need it on your project.
  // container: PropTypes.object
};

const styles = theme => ({
  root: {
    display: "flex"
  },
  drawer: {
    [theme.breakpoints.up("md")]: {
      flexShrink: 0
    }
  },
  drawerPaper: {
    // width: DRAWER_WIDTH
  },
  content: {
    flexGrow: 1,
    marginTop: NAVBAR_HEIGHT,
    [theme.breakpoints.up("md")]: {
      width: `calc(100vw - ${DRAWER_WIDTH}px)`,
      marginLeft: DRAWER_WIDTH
    },
    padding: theme.spacing(0)
  }
});

const mapStateToProps = state => {
  const { main } = state;
  return {
    main
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getChannelList: (callback, catId) =>
      dispatch(getChannelList(callback, catId)),
    getProgramList: silentRun => dispatch(getProgramList(silentRun)),
    getVideoList: () => dispatch(getVideoList()),
    getCategoryList: () => dispatch(getCategoryList()),
    toggleFullscreen: isFullscreen => dispatch(toggleFullscreen(isFullscreen)),
    toggleDrawer: isOpen => dispatch(toggleDrawer(isOpen)),
    closeTVGuide: isShown => dispatch(toggleTVGuide(isShown)),
    completeLoading: () => dispatch(completeLoading()),
    showClock: isShown => dispatch(showClock(isShown)),
    setScreenDimension: (height, width) =>
      dispatch(setScreenDimension(height, width)),
    setOrientation: orientation => dispatch(setOrientation(orientation)),
    setTabId: tabId => dispatch(setTabId(tabId))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(compose(withStyles(styles))(MainView));
