import axios from "axios";
import moment from "moment";
import "moment-timezone";
import {
  URL_PROGRAM_LIST_API,
  URL_UPDATE_PROGRAMS_API,
  GET_PROGRAM_LIST,
  GET_PROGRAM_LIST_SUCCESS,
  GET_PROGRAM_LIST_FAILURE,
  SET_PROGRAM_DETAIL,
  START_PROGRAM_UPDATE,
  PROGRAM_UPDATE_SUCCESS,
  PROGRAM_UPDATE_FAILURE,
  RESET_PROGRAM_MESSAGE,
  ERROR_NETWORK_CONNECTION
} from "../constants";

const convertToLocalTime = data => {
  const timestamp = new Date();
  let timeDiff =
    moment(timestamp).format("H") -
    moment(timestamp)
      .tz("Asia/Seoul")
      .format("H");
  if (timeDiff < 0) timeDiff += 24;
  let hours;
  data.map(program => {
    if (program.from !== "") {
      hours = parseInt(program.from.slice(0, 2)) + timeDiff;
      if (hours > 23) hours -= 24;
      program.from =
        ("0" + hours.toString()).slice(-2) + program.from.slice(-3);
    }
    if (program.till !== "") {
      hours = parseInt(program.till.slice(0, 2)) + timeDiff;
      if (hours > 23) hours -= 24;
      program.till =
        ("0" + hours.toString()).slice(-2) + program.till.slice(-3);
    }
  });
};

export const getProgramList = (silentRun = false) => {
  const now = new Date();
  return (dispatch, getState) => {
    if (now - getState().programs.lastCheckedTime > 10000) {
      let url = URL_PROGRAM_LIST_API;
      dispatch(getProgramListStarted(silentRun));
      axios
        .get(url)
        .then(res => {
          const { currentChannel } = getState().main;
          convertToLocalTime(res.data);
          dispatch(getProgramListSuccess(res.data, currentChannel));
        })
        .catch(err => {
          dispatch(getProgramListFailure(err.message));
        });
    }
  };
};

const getProgramListStarted = silentRun => ({
  type: GET_PROGRAM_LIST,
  payload: silentRun
});

const getProgramListSuccess = (programs, currentChannel) => ({
  type: GET_PROGRAM_LIST_SUCCESS,
  payload: {
    programs: [...programs],
    currentChannel
  }
});

const getProgramListFailure = error => ({
  type: GET_PROGRAM_LIST_FAILURE,
  payload: error
});

export const setProgramDetail = currentChannel => {
  return dispatch => {
    dispatch({
      type: SET_PROGRAM_DETAIL,
      payload: currentChannel
    });
  };
};

export const updateProgramList = () => {
  return (dispatch, getState) => {
    dispatch(programUpdateStarted());
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${getState().auth.token}`
    };
    axios
      // .post(URL_UPDATE_PROGRAMS_API, null, { headers })
      .get(URL_UPDATE_PROGRAMS_API, { param: null, headers })
      .then(res => {
        console.log("Program list update was successful", res.data.msg);
        dispatch(programUpdateSuccess("Program list update was successful"));
      })
      .catch(err => {
        console.log("update failed", err);
        let errMessage;
        switch (err.message) {
          case "Network Error":
            errMessage = ERROR_NETWORK_CONNECTION;
            break;
          default:
            errMessage = err.message;
        }
        dispatch(programUpdateFailure(errMessage));
      });
    // setTimeout(() => dispatch(programUpdateSuccess("it worked")), 5000);
  };
};

const programUpdateStarted = () => ({
  type: START_PROGRAM_UPDATE
});

const programUpdateSuccess = data => ({
  type: PROGRAM_UPDATE_SUCCESS,
  payload: data
});

const programUpdateFailure = error => ({
  type: PROGRAM_UPDATE_FAILURE,
  payload: error
});

export const resetProgramMessage = () => ({
  type: RESET_PROGRAM_MESSAGE
});
