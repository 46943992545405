export const PACKAGE_ID = process.env.REACT_APP_PACKAGE_ID;
export const DEBUG = process.env.NODE_ENV !== "production" ? true : false;
export const API_HOST = process.env.REACT_APP_API_HOST;
// export const API_HOST = "http://gogogo.sytes.net:8000/api/";
export const URL_SERVICE_INFO_API = API_HOST + "serviceinfo/";
export const URL_CHANNEL_LIST_API = API_HOST + "channels/";
export const URL_CATEGORY_LIST_API = API_HOST + "categories/";
export const URL_PROGRAM_LIST_API = API_HOST + "programs/";
export const URL_VIDEO_LIST_API = API_HOST + "popularvideo/";
export const URL_LOGIN_API = API_HOST + "rest-auth/login/";
export const URL_LOGOUT_API = API_HOST + "rest-auth/logout/";
export const URL_USER_PRIVILEGE_API = API_HOST + "rest-auth/userprivilege/";
export const URL_CHANGE_PASSWORD_API = API_HOST + "rest-auth/password/change/";
export const URL_UPDATE_CHANNELS_API = API_HOST + "channels/update/";
export const URL_UPDATE_PROGRAMS_API = API_HOST + "updateprograms/";
export const URL_SEARCH_CITY_API = API_HOST + "citysearch/";

export const URL_MAIN_TVGUIDE = process.env.REACT_APP_URL_MAIN_TVGUIDE;

export const OPENWEATHERMAP_API_KEY =
  process.env.REACT_APP_OPENWEATHERMAP_API_KEY;

export const VIDEO_CONTROL_AUTO_HIDE_DURATION = 10000;
export const VIDEO_LOADING_MAX_DURATION = 10000;
export const DRAWER_WIDTH = 275;
export const NAVBAR_HEIGHT = 48;
export const VBREAKUP_SM = 280;
export const VBREAKUP_MD = 400;
export const VBREAKUP_LG = 600;

export const LOGIN_NO_OF_ATTEMPTS = 3;
export const LOGIN_RETRY_DURATION = 30;

export const WEATHER_UPDATE_INTERVAL = 1000 * 60 * 60;
export const CHANNEL_UPDATE_INTERVAL = 1000 * 60 * 60;

const timeoutValue = parseInt(process.env.REACT_APP_MAX_LOADING_RESET_TIMEOUT);
export const MAX_LOADING_RESET_TIMEOUT = isNaN(timeoutValue)
  ? 30 * 1000
  : timeoutValue * 1000;
export const MAX_BITRATE = 200000;

export const MAX_YOUTUBE_RESULTS = 40;

export const HOMEPAGE_URL = process.env.REACT_APP_HOMEPAGE_URL;
export const SUPPORT_EMAIL_ADDRESS =
  process.env.REACT_APP_SUPPORT_EMAIL_ADDRESS;

export const ADMOB_BANNER_ADUNITID =
  process.env.REACT_APP_ADMOB_BANNER_ADUNITID; // Live banner
export const ADMOB_INTERSTITIAL_ADUNITID =
  process.env.REACT_APP_ADMOB_INTERSTITIAL_ADUNITID; // Live interstitial
// export const ADMOB_BANNER_ADUNITID = "ca-app-pub-3940256099942544/6300978111"; // Test banner
// export const ADMOB_INTERSTITIAL_ADUNITID =
//   "ca-app-pub-3940256099942544/8691691433"; // Test interstitial
console.log(process.env);
