import axios from "axios";
import {
  URL_CHANNEL_LIST_API,
  GET_CHANNEL_LIST,
  GET_CHANNEL_LIST_SUCCESS,
  GET_CHANNEL_LIST_FAILURE,
  ERROR_NETWORK_CONNECTION,
  RESET_CHANNEL_ERROR,
  RESET_UPDATE_MESSAGE,
  RESET_CHANNEL_MESSAGE,
  URL_UPDATE_CHANNELS_API,
  START_CHANNEL_UPDATE,
  CHANNEL_UPDATE_SUCCESS,
  CHANNEL_UPDATE_FAILURE
} from "../constants";

export const getChannelList = (callback, catId = 0) => {
  return (dispatch, getState) => {
    dispatch(getChannelListStarted());
    let powerUser = `${getState().auth.isAuthenticated ? "1" : "0"}/`;
    let channelListAPIURL = URL_CHANNEL_LIST_API + powerUser;
    if (catId !== 0) {
      channelListAPIURL += `category/${catId.toString()}/`;
    }
    // channelListAPIURL += "category/" + getState().main.categoryId;
    axios
      .get(channelListAPIURL)
      .then(res => {
        dispatch(getChannelListSuccess(catId, res.data));
        callback && callback();
      })
      .catch(err => {
        console.log(
          "getChannelList",
          new Date(),
          channelListAPIURL,
          err.message
        );
        let errMessage;
        switch (err.message) {
          case "Network Error":
            errMessage = ERROR_NETWORK_CONNECTION;
            break;
          default:
            errMessage = err.message;
        }
        dispatch(getChannelListFailure(errMessage));
      });
  };
};

const getChannelListStarted = () => ({
  type: GET_CHANNEL_LIST
});

const getChannelListSuccess = (catId, channels) => ({
  type: GET_CHANNEL_LIST_SUCCESS,
  payload: {
    catId,
    channels: [...channels]
  }
});

const getChannelListFailure = error => ({
  type: GET_CHANNEL_LIST_FAILURE,
  payload: error
});

export const resetChannelError = () => {
  return {
    type: RESET_CHANNEL_ERROR
  };
};

export const resetUpdateMessage = () => {
  return {
    type: RESET_UPDATE_MESSAGE
  };
};

export const resetChannelMessage = () => {
  return {
    type: RESET_CHANNEL_MESSAGE
  };
};

export const updateChannelLink = () => {
  return (dispatch, getState) => {
    dispatch(channelUpdateStarted());
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${getState().auth.token}`
    };
    axios
      .post(URL_UPDATE_CHANNELS_API, null, { headers })
      .then(res => {
        console.log("update succeded", res.data.msg);
        dispatch(channelUpdateSuccess(res.data.msg));
      })
      .catch(err => {
        console.log("update failed", err);
        let errMessage;
        switch (err.message) {
          case "Network Error":
            errMessage = ERROR_NETWORK_CONNECTION;
            break;
          default:
            errMessage = err.message;
        }
        dispatch(channelUpdateFailure(errMessage));
      });
    // setTimeout(() => dispatch(channelUpdateSuccess("it worked")), 5000);
  };
};

const channelUpdateStarted = () => ({
  type: START_CHANNEL_UPDATE
});

const channelUpdateSuccess = data => ({
  type: CHANNEL_UPDATE_SUCCESS,
  payload: data
});

const channelUpdateFailure = error => ({
  type: CHANNEL_UPDATE_FAILURE,
  payload: error
});
