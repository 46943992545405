import {
  SET_ORIENTATION,
  SET_SCREEN_DIMENSION,
  TOGGLE_FULLSCREEN,
  TOGGLE_TVGUIDE,
  TOGGLE_DRAWER,
  SET_CHANNEL_IDX,
  // SET_CATEGORY,
  SET_MEDIA_URL,
  SET_VIDEO_URL,
  RESET_MEDIA_URL,
  SET_CHANNEL_URL,
  HANDLE_WEBVIEW_PRESS,
  COMPLETE_LOADING,
  UPDATE_PROGRAM,
  HANDLE_VIDEO_PLAY,
  HANDLE_VIDEO_ERROR,
  RESET_VIDEO_ERROR,
  SET_CELLULAR_PERMISSION,
  SET_NETWORK_TYPE,
  SET_VERSION,
  SET_TAB_ID,
  SET_HLS_EXT_REQUIRED
} from "../constants";

const initialState = {
  loading: false,
  tvGuideLoading: false,
  orientation: "PORTRAIT",
  screenHeight: null,
  screenWidth: null,
  version: "1.0.0",
  tabId: 0,
  isPlaying: false,
  isFullscreen: false,
  showTVGuide: false,
  showDrawer: false,
  // categoryId: "",
  currentChannel: "",
  currentChannelIdx: "",
  mediaURL: "",
  homepageURL: "",
  tvGuideURL: "",
  posterURL: "",
  copyrightHolder: "",
  displayName: "",
  currentProgram: "",
  nextProgram: "",
  startTime: "",
  videoError: null,
  canGoBack: false,
  cellularPermitted: false,
  networkType: "",
  hlsExtRequired: false
};

export default function mainReducer(state = initialState, action) {
  switch (action.type) {
    case SET_ORIENTATION:
      return {
        ...state,
        orientation: action.payload
      };
    case SET_SCREEN_DIMENSION:
      return {
        ...state,
        screenHeight: action.payload.screenHeight,
        screenWidth: action.payload.screenWidth
      };
    case TOGGLE_FULLSCREEN:
      return {
        ...state,
        isFullscreen: action.payload
      };
    case SET_CHANNEL_IDX:
      return {
        ...state,
        currentChannelIdx: action.payload
      };
    case TOGGLE_TVGUIDE:
      return {
        ...state,
        showTVGuide: action.payload.showTVGuide
        // loading: action.payload.loading
      };
    case TOGGLE_DRAWER:
      return {
        ...state,
        showDrawer: action.payload
      };
    // case SET_CATEGORY:
    //   return {
    //     ...state,
    //     categoryId: action.payload
    //   };
    case SET_MEDIA_URL:
      return {
        ...state,
        loading: true,
        isPlaying: false,
        currentChannel: action.payload.channel,
        homepageURL: action.payload.homepageURL,
        posterURL: action.payload.posterURL,
        copyrightHolder: action.payload.copyrightHolder,
        displayName: action.payload.displayName,
        mediaURL: action.payload.url,
        currentChannelIdx: action.payload.idx,
        videoError: null
      };
    case RESET_MEDIA_URL:
      return {
        ...state,
        loading: false,
        mediaURL: "",
        homepageURL: "",
        posterURL: ""
      };
    case SET_VIDEO_URL:
      return {
        ...state,
        mediaURL: action.payload
      };
    case SET_CHANNEL_URL:
      return {
        ...state,
        tvGuideURL: action.payload,
        tvGuideLoading: true,
        // isPlaying: false,
        showTVGuide: true,
        canGoBack: false
      };
    case COMPLETE_LOADING:
      return {
        ...state,
        loading: false,
        tvGuideLoading: false
      };
    case UPDATE_PROGRAM:
      return {
        ...state,
        currentProgram: action.payload.currentProgram,
        nextProgram: action.payload.nextProgram,
        startTime: action.payload.startTime
      };
    case HANDLE_VIDEO_PLAY:
      return {
        ...state,
        loading: false,
        isPlaying: true,
        videoError: null,
        currentChannelIdx: action.payload
      };
    case HANDLE_VIDEO_ERROR:
      return {
        ...state,
        loading: false,
        isPlaying: false,
        videoError: action.payload.error,
        currentChannelIdx: action.payload.idx,
        // showDrawer: true,
        mediaURL: action.payload.mediaURL
      };
    case RESET_VIDEO_ERROR:
      return {
        ...state,
        loading: false,
        videoError: null
      };
    case HANDLE_WEBVIEW_PRESS:
      return {
        ...state,
        canGoBack: action.payload
      };
    case SET_CELLULAR_PERMISSION:
      if (action.payload) {
        return {
          ...state,
          cellularPermitted: true
        };
      }
      return {
        ...state,
        cellularPermitted: false,
        mediaURL: ""
      };
    case SET_NETWORK_TYPE:
      return {
        ...state,
        networkType: action.payload
      };
    case SET_VERSION:
      return {
        ...state,
        version: action.payload
      };
    case SET_TAB_ID:
      return {
        ...state,
        tabId: action.payload
      };
    case SET_HLS_EXT_REQUIRED:
      return {
        ...state,
        hlsExtRequired: action.payload
      };
    default:
      return state;
  }
}
