import {
  DRAWER_WIDTH,
  SET_ORIENTATION,
  SET_SCREEN_DIMENSION,
  TOGGLE_FULLSCREEN,
  TOGGLE_TVGUIDE,
  TOGGLE_DRAWER,
  SET_MEDIA_URL,
  SET_VIDEO_URL,
  RESET_MEDIA_URL,
  HANDLE_VIDEO_PLAY,
  HANDLE_VIDEO_ERROR,
  RESET_VIDEO_ERROR,
  SET_CHANNEL_URL,
  HANDLE_WEBVIEW_PRESS,
  COMPLETE_LOADING,
  UPDATE_PROGRAM,
  VIDEO_LOADING_MAX_DURATION,
  ERROR_VIDEO_LOADING,
  SET_CELLULAR_PERMISSION,
  SET_NETWORK_TYPE,
  SET_VERSION,
  SET_TAB_ID,
  SET_HLS_EXT_REQUIRED
} from "../constants";

export const setOrientation = orientation => {
  return dispatch => {
    dispatch({
      type: SET_ORIENTATION,
      payload: orientation
    });
  };
};

export const setScreenDimension = (screenHeight, screenWidth) => {
  return dispatch => {
    dispatch({
      type: SET_SCREEN_DIMENSION,
      payload: {
        screenHeight,
        screenWidth
      }
    });
  };
};

export const toggleFullscreen = isFullscreen => {
  return dispatch => {
    dispatch({
      type: TOGGLE_FULLSCREEN,
      payload: isFullscreen
    });
  };
};

export const toggleTVGuide = showTVGuide => {
  return dispatch => {
    dispatch({
      type: TOGGLE_TVGUIDE,
      payload: {
        showTVGuide
      }
    });
  };
};

export const toggleDrawer = isOpen => {
  return dispatch => {
    dispatch({
      type: TOGGLE_DRAWER,
      payload: isOpen
    });
  };
};

export const setMediaURL = (channel, url, idx) => {
  // const errorMessage = ERROR_VIDEO_LOADING(channel, idx + 1);
  // const mediaURL = "";
  let homepageURL = "";
  let posterURL = "";
  let copyrightHolder = "";
  let displayName = "";
  return (dispatch, getState) => {
    const currentChannel = getState().channels.channels.filter(item => {
      if (item.name === channel) {
        return item;
      }
      return null;
    });
    if (currentChannel !== null && currentChannel.length > 0) {
      homepageURL = currentChannel[0].homepage_url;
      posterURL = currentChannel[0].poster_url;
      copyrightHolder = currentChannel[0].copyright_holder;
      displayName =
        currentChannel[0].display_name === ""
          ? currentChannel[0].name
          : currentChannel[0].display_name;
    }

    // const homepageURL =
    //   currentChannel !== null && currentChannel.length > 0
    //     ? currentChannel[0].homepage_url
    //     : "";
    // const posterURL =
    //   currentChannel !== null && currentChannel.length > 0
    //     ? currentChannel[0].poster_url
    //     : "";
    dispatch({
      type: SET_MEDIA_URL,
      payload: {
        channel,
        homepageURL,
        posterURL,
        copyrightHolder,
        displayName,
        url,
        idx
      }
    });

    // setTimeout(() => {
    //   console.log()
    //   if (!getState().main.isPlaying) {
    //     dispatch({
    //       type: HANDLE_VIDEO_ERROR,
    //       payload: {
    //         error: errorMessage,
    //         idx,
    //         mediaURL
    //       }
    //     });
    //   }
    // }, VIDEO_LOADING_MAX_DURATION);
  };
};

export const resetMediaURL = () => {
  return dispatch => {
    dispatch({
      type: RESET_MEDIA_URL
    });
  };
};

export const setVideoURL = url => {
  return dispatch => {
    dispatch({
      type: SET_VIDEO_URL,
      payload: url
    });
  };
};

export const setChannelURL = url => {
  return dispatch => {
    dispatch({
      type: SET_CHANNEL_URL,
      payload: url
    });
  };
};

export const handleWebviewPress = canGoBack => {
  return dispatch => {
    dispatch({
      type: HANDLE_WEBVIEW_PRESS,
      payload: canGoBack
    });
  };
};

export const handleVideoPlay = idx => {
  return dispatch => {
    dispatch({
      type: HANDLE_VIDEO_PLAY,
      payload: idx
    });
  };
};

export const handleVideoError = (error, idx) => {
  return (dispatch, getState) => {
    const { errorException } = error;
    let errorMessage = errorException;
    let mediaURL = getState().main.mediaURL;
    if (typeof errorException === "string") {
      if (
        errorException.includes("Response code: 40") ||
        errorException.includes("Unable to connect to")
      ) {
        errorMessage = ERROR_VIDEO_LOADING(
          getState().main.currentChannel,
          idx + 1
        );
        dispatch({
          type: HANDLE_VIDEO_ERROR,
          payload: {
            error: errorMessage,
            idx,
            mediaURL: ""
          }
        });
      }
    }
  };
  // switch (error.errorException.includes) {
  //   case "Response code: 404":
  //     // errorMessage = ERROR_VIDEO_LOADING(getState().main.currentChannel, idx + 1);
  //     // errorMessage = null;
  //     // mediaURL = "";
  //     return null;
  //   case "error.errorException"
  //   default:
  //     mediaURL = getState().main.mediaURL;
  //     errorMessage = null;
  // }
};

export const completeLoading = () => {
  return dispatch => {
    dispatch({
      type: COMPLETE_LOADING
    });
  };
};

export const updateProgram = (currentProgram, nextProgram, startTime) => {
  return dispatch => {
    dispatch({
      type: UPDATE_PROGRAM,
      payload: {
        currentProgram,
        nextProgram,
        startTime
      }
    });
  };
};

export const setVideoError = (errorMessage, idx) => {
  return dispatch => {
    dispatch({
      type: HANDLE_VIDEO_ERROR,
      payload: {
        error: errorMessage,
        idx,
        mediaURL: ""
      }
    });
  };
};

export const resetVideoError = () => {
  return dispatch => {
    dispatch({
      type: RESET_VIDEO_ERROR
    });
  };
};

export const setCellularPermission = isPermitted => {
  return dispatch => {
    dispatch({
      type: SET_CELLULAR_PERMISSION,
      payload: isPermitted
    });
  };
};

export const setNetworkType = networkType => {
  return dispatch => {
    dispatch({
      type: SET_NETWORK_TYPE,
      payload: networkType
    });
  };
};

export const setVersion = version => {
  return dispatch => {
    dispatch({
      type: SET_VERSION,
      payload: version
    });
  };
};

export const setTabId = tabId => {
  return dispatch => {
    dispatch({
      type: SET_TAB_ID,
      payload: tabId
    });
  };
};

export const setHLSExtRequired = isRequired => {
  return dispatch => {
    dispatch({
      type: SET_HLS_EXT_REQUIRED,
      payload: isRequired
    });
  };
};
