import {
  GET_VIDEO_LIST,
  GET_VIDEO_LIST_SUCCESS,
  GET_VIDEO_LIST_FAILURE,
  // SET_VIDEO_URL,
  TOGGLE_VIDEO_TAB,
  COMPLETE_VIDEO_LOADING
} from "../constants";

const initialState = {
  loading: false,
  videoLoading: false,
  tabLoaded: false,
  videos: [],
  error: null,
  videoURL: null,
  currentPlaying: -1
};

export default function videoReducer(state = initialState, action) {
  switch (action.type) {
    case GET_VIDEO_LIST:
      return {
        ...state,
        loading: true,
        tabLoaded: true,
        videos: []
      };
    case GET_VIDEO_LIST_SUCCESS: {
      const { videos } = action.payload;
      return {
        ...state,
        loading: false,
        videos,
        error: null,
        videoURL: null
      };
    }
    case GET_VIDEO_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        videoURL: null
      };
    // case SET_VIDEO_URL:
    //   return {
    //     ...state,
    //     videoURL: `https://www.youtube.com/watch?v=${action.payload.url}`
    //     // currentPlaying: action.payload.index,
    //     // videoLoading: true,
    //   };
    case COMPLETE_VIDEO_LOADING:
      return {
        ...state,
        videoLoading: false
      };
    case TOGGLE_VIDEO_TAB:
      return {
        ...state,
        tabLoaded: action.payload
      };
    default:
      return state;
  }
}
