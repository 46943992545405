import React, { Component } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { IconButton, Snackbar, SnackbarContent } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import InfoIcon from "@material-ui/icons/Info";
import CloseIcon from "@material-ui/icons/Close";
import WarningIcon from "@material-ui/icons/Warning";
import { amber, green } from "@material-ui/core/colors";

class SnackbarWrapper extends Component {
  _handleClose = () => {
    this.props.onClose();
  };

  render() {
    const {
      classes,
      className,
      message,
      variant,
      duration = 3200,
      open = true
    } = this.props;
    const Icon = variantIcon[variant];
    return (
      <Snackbar
        className={classes.snackbar}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center"
        }}
        open={open}
        autoHideDuration={duration}
        onClose={this._handleClose}
        transitionDuration={0}
      >
        <SnackbarContent
          className={clsx(classes[variant], className)}
          aria-describedby="client-snackbar"
          message={
            <span id="client-snackbar" className={classes.message}>
              <Icon className={clsx(classes.icon, classes.iconVariant)} />
              {message}
            </span>
          }
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              onClick={this._handleClose}
            >
              <CloseIcon className={classes.icon} />
            </IconButton>
          ]}
        />
      </Snackbar>
    );
  }
}

SnackbarWrapper.propTypes = {
  className: PropTypes.string,
  message: PropTypes.node,
  onClose: PropTypes.func,
  variant: PropTypes.oneOf(["success", "warning", "error", "info"]).isRequired
};

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon
};

const styles = theme => ({
  success: {
    backgroundColor: green[600]
  },
  error: {
    backgroundColor: theme.palette.error.dark,
    color: theme.palette.text.default
  },
  info: {
    backgroundColor: theme.palette.secondary.main
  },
  warning: {
    backgroundColor: amber[700]
  },
  snackbar: {
    maxWidth: theme.spacing(55),
    zIndex: 9999
  },
  icon: {
    fontSize: 20
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1)
  },
  message: {
    display: "flex",
    alignItems: "center",
    fontWeight: "bold"
  },
  margin: {
    margin: theme.spacing(1)
  }
});

export default withStyles(styles)(SnackbarWrapper);
