import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { Box, Grid, Link } from "@material-ui/core";
import { withStyles, withTheme } from "@material-ui/styles";
import kOglLogo from "../../assets/images/KOGL_logo_s.jpg";

class ProgramTitle extends Component {
  render() {
    const { classes, theme } = this.props;
    const {
      currentChannel,
      homepageURL,
      copyrightHolder,
      displayName
    } = this.props.main;
    const {
      currentProgram,
      nextProgram,
      startTime,
      finishTime
    } = this.props.programs;
    return (
      <div>
        <Grid container direction="column" alignItems="center">
          <Grid item>
            <Box display="flex" justifyContent="center">
              <Box fontSize={13} className={classes.channelName}>
                {displayName}
              </Box>
            </Box>
            <Box m={0.5}>
              {!!currentProgram ? (
                <Box
                  display={"flex"}
                  fontSize={11}
                  wrap={"wrap"}
                  color={theme.palette.text.primary}
                >
                  <Box mr={0.5}>Now:</Box>
                  <Box>
                    {startTime} {currentProgram}
                  </Box>
                </Box>
              ) : null}
              {!!nextProgram ? (
                <Box
                  display={"flex"}
                  fontSize={11}
                  wrap={"wrap"}
                  color={theme.palette.text.secondary}
                >
                  <Box mr={0.5}>Next:</Box>
                  <Box>
                    {finishTime} {nextProgram}
                  </Box>
                </Box>
              ) : null}
            </Box>
          </Grid>
          {!!copyrightHolder ? (
            <Grid item>
              <Box display={"flex"} className={classes.copyrightContainerStyle}>
                <Box fontSize={11} mr={0.5} wrap={"wrap"} alignSelf={"center"}>
                  <Link
                    href={!!homepageURL ? homepageURL : undefined}
                    target={"_blank"}
                  >
                    The copyright of this broadcast belongs to {copyrightHolder}{" "}
                    and their licensors. 본 방송의 저작권은 {copyrightHolder}{" "}
                    그리고 관련 방송의 라이센스 허가자에 있습니다.
                  </Link>
                </Box>
                <Box>
                  {currentChannel.includes("아리랑") ||
                  currentChannel.includes("tbs") ||
                  currentChannel.includes("국민방송") ? (
                    <img src={kOglLogo} />
                  ) : null}
                </Box>
              </Box>
            </Grid>
          ) : null}
        </Grid>
      </div>
    );
  }
}

const styles = theme => ({
  channelName: {
    fontWeight: "bold",
    color: theme.palette.text.black,
    backgroundColor: theme.palette.primary.main,
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    borderRadius: 3,
    maxWidth: theme.spacing(45)
  },
  copyrightContainerStyle: {
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5)
  }
});

const mapStateToProps = state => {
  return {
    main: state.main,
    programs: state.programs
  };
};

export default connect(mapStateToProps)(
  compose(withStyles(styles, { withTheme: true }))(ProgramTitle)
);
