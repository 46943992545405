import { createMuiTheme, responsiveFontSizes } from "@material-ui/core/styles";
import {
  blue,
  green,
  grey,
  lightBlue,
  orange,
  red
} from "@material-ui/core/colors";
// import { dark } from "@material-ui/core/styles/createPalette";

const theme = responsiveFontSizes(
  createMuiTheme({
    palette: {
      type: "dark",
      primary: {
        light: orange[300],
        main: orange[500],
        dark: orange[800]
      },
      secondary: {
        light: blue[300],
        main: blue[500],
        dark: blue[800]
      },
      error: {
        light: red[300],
        main: red[500],
        dark: red[800]
      },
      success: {
        light: green[300],
        main: green[500],
        dark: green[900]
      },
      background: {
        default: "#000",
        lightest: grey[300],
        lighter: grey[500],
        light: grey[800],
        dark: grey[900],
        white: "#fff"
      },
      text: {
        default: "#fff",
        primary: lightBlue[100],
        secondary: "#fff",
        black: "#000"
      },
      action: {
        default: orange[500],
        primary: lightBlue[50],
        secondary: "#fff"
      },
      typography: {
        // In Chinese and Japanese the characters are usually larger,
        // so a smaller fontsize may be appropriate.
        fontSize: 13
      }
    }
  })
);

export default theme;
