import axios from "axios";
import {
  URL_LOGIN_API,
  URL_USER_PRIVILEGE_API,
  START_LOGIN,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  HANDLE_TEXT_INPUT,
  URL_LOGOUT_API,
  START_LOGOUT,
  LOGOUT_SUCCESS,
  LOGOUT_FAILURE,
  URL_CHANGE_PASSWORD_API,
  START_PASSWORD_CHANGE,
  PASSWORD_CHANGE_SUCCESS,
  PASSWORD_CHANGE_FAILURE,
  RESET_LOGIN_ERROR,
  RESET_ALL_PASSWORDS,
  RESET_AUTH_MESSAGE
} from "../constants";

export const startLogin = () => {
  return (dispatch, getState) => {
    const { username, password } = getState().auth;
    dispatch(loginStarted());
    console.log("startLogin", username, password);
    setTimeout(() => {
      console.log("isPermitted", getState().auth.isPermitted);
      if (getState().auth.isPermitted) {
        axios
          .post(URL_LOGIN_API, { username, password })
          .then(res => {
            const { key } = res.data;
            // const headers = {
            //   "Content-Type": "application/json",
            //   Authorization: `Token ${key}`
            // };
            console.log("Login success : ", key);
            // dispatch(loginSuccess(key, username));
            // console.log(URL_USER_PRIVILEGE_API, headers);
            axios
              .get(URL_USER_PRIVILEGE_API, {
                headers: { Authorization: `Token ${key}` }
              })
              .then(res => {
                console.log(res.data);
                const userPrivilege = res.data;
                dispatch(loginSuccess(key, username, userPrivilege));
              })
              .catch(err => console.log(err));
          })
          .catch(err => {
            let errMessage;
            if (err.message === "Request failed with status code 400") {
              errMessage =
                "Unable to login with the provided credentials. Please try agian.";
            } else {
              errMessage = err.message;
            }
            console.log("Login failed", err.message);
            dispatch(loginFailure(errMessage));
          });
      }
    }, 500);
  };
};

const loginStarted = () => ({
  type: START_LOGIN
});

const loginSuccess = (token, username, userPrivilege, firstName, lastName) => ({
  type: LOGIN_SUCCESS,
  payload: {
    token,
    username,
    userPrivilege,
    firstName,
    lastName
  }
});

const loginFailure = error => ({
  type: LOGIN_FAILURE,
  payload: error
});

export const handleInput = (inputField, value) => ({
  type: HANDLE_TEXT_INPUT,
  payload: {
    inputField,
    value
  }
});

export const startLogout = () => {
  return dispatch => {
    dispatch(logoutStarted());
    axios
      .post(URL_LOGOUT_API, {})
      .then(() => {
        // Actions.main();
        dispatch(logoutSuccess("Logged out successfully."));
        // NavigationService.navigate('main');
      })
      .catch(error => {
        dispatch(logoutFailure(error));
      });
  };
};

const logoutStarted = () => ({
  type: START_LOGOUT
});

const logoutSuccess = message => ({
  type: LOGOUT_SUCCESS,
  payload: message
});

const logoutFailure = errMessage => ({
  type: LOGOUT_FAILURE,
  payload: errMessage
});

export const startPasswordChange = () => {
  return (dispatch, getState) => {
    const { oldPassword, password1, password2 } = getState().auth;
    if (password1 !== password2) {
      return dispatch(
        passwordChangeFailure("New and confirm passwords do not match.")
      );
    }
    if (oldPassword === password1) {
      return dispatch(
        passwordChangeFailure(
          "New password cannot be the same as your old password."
        )
      );
    }
    const postData = {
      old_password: oldPassword,
      new_password1: password1,
      new_password2: password2
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${getState().auth.token}`
    };
    dispatch(passwordChangeStarted());
    console.log("headers", headers);
    axios
      .post(URL_CHANGE_PASSWORD_API, postData, { headers })
      .then(() => {
        dispatch(
          passwordChangeSuccess("Password has been updated successfully.")
        );
      })
      .catch(err => {
        console.log("error", err);
        dispatch(passwordChangeFailure(err.message));
      });
  };
};

const passwordChangeStarted = () => ({
  type: START_PASSWORD_CHANGE
});

const passwordChangeSuccess = message => ({
  type: PASSWORD_CHANGE_SUCCESS,
  payload: message
});

const passwordChangeFailure = errMessage => ({
  type: PASSWORD_CHANGE_FAILURE,
  payload: errMessage
});

export const resetAllPasswords = () => ({
  type: RESET_ALL_PASSWORDS
});

export const resetLoginError = () => ({
  type: RESET_LOGIN_ERROR
});

export const resetAuthMessage = () => ({
  type: RESET_AUTH_MESSAGE
});
