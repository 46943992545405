import axios from 'axios';
// import { Alert } from 'react-native';
import moment from 'moment';
import 'moment/locale/ko';
import {
  GET_GEO_LOCATION,
  GET_WEATHER_INFO_SUCCESS,
  GET_WEATHER_INFO_FAILURE,
  SET_BATTERY_INFO,
  GET_DATETIME_INFO,
  OPENWEATHERMAP_API_KEY,
  ERROR_NETWORK_CONNECTION,
  SET_FORECAST_INFO,
  SET_CITY_LIST,
  ADD_CITY,
  REMOVE_CITY,
  REORDER_CITY,
  SHOW_CLOCK,
} from '../constants';


const localeDate = dt => {
  const date = new Date(dt * 1000);
  moment.locale('ko');
  return moment(date).format('LLLL').slice(6, -8).trim();
}

const getLocalHour = dt => {
  const date = new Date(dt * 1000);
  return Number(moment(date).format('H'));
}

const formatHour = hour => {
  if (hour === 12) {
    return `오후 12시`;
  } else if (hour > 12) {
    return `오후 ${hour - 12}시`;
  } else {
    return `오전 ${hour}시`;
  }
}

export const getGeoLocation = () => {
  return dispatch => {
    navigator.geolocation.getCurrentPosition((position) => {
      dispatch({
        type: GET_GEO_LOCATION,
        payload: position.coords
      });
    },
      (err) => {
        console.log(err);
      },
      { enableHighAccuracy: false, timeout: 30000, maximumAge: 1000 },
    );
  };
}

export const getWeatherInfo = (cityIndex = null) => {
  return (dispatch, getState) => {
    const { latitude, longitude, cities } = getState().info;
    const units = (getState().settings.weatherUnitMetric) ? "metric" : "imperial";
    let openWeatherMapCurrentAPI = `https://api.openweathermap.org/data/2.5/weather?`;
    let openWeatherMapForecastAPI = `https://api.openweathermap.org/data/2.5/forecast?`;
    if (cityIndex === null) {
      if (cities === null || cities.length === 0) {
        openWeatherMapCurrentAPI += `lat=${latitude}&lon=${longitude}`;
        openWeatherMapForecastAPI += `lat=${latitude}&lon=${longitude}`;
      } else {
        openWeatherMapCurrentAPI += `q=${cities[0].name},${cities[0].country}`;
        openWeatherMapForecastAPI += `q=${cities[0].name},${cities[0].country}`;
      }
    } else {
      openWeatherMapCurrentAPI += `q=${cities[cityIndex].name},${cities[cityIndex].country}`;
      openWeatherMapForecastAPI += `q=${cities[cityIndex].name},${cities[cityIndex].country}`;
    }
    openWeatherMapCurrentAPI += `&APPID=${OPENWEATHERMAP_API_KEY}&units=${units}`;
    openWeatherMapForecastAPI += `&APPID=${OPENWEATHERMAP_API_KEY}&units=${units}`;

    axios.get(openWeatherMapCurrentAPI)
      .then(res => {
        console.log(res.data);
        dispatch(getWeatherInfoSuccess(res.data, "current"));
        return Promise.resolve();
      })
      .catch(err => {
        dispatch(getWeatherInfoFailure(err.message))
      });


    axios.get(openWeatherMapForecastAPI)
      .then(res => {
        const forecast = parseForecastData(res.data);
        console.log("WEAHTER FORECAST", res.data);
        dispatch(getWeatherInfoSuccess(forecast, "forecast"));
      })
      .catch(err => {
        dispatch(getWeatherInfoFailure(err.message));
      });
  }
}

const getWeatherInfoSuccess = (weatherInfo, weatherType) => ({
  type: GET_WEATHER_INFO_SUCCESS,
  payload: {
    weatherInfo,
    weatherType
  }
});

const getWeatherInfoFailure = error => ({
  type: GET_WEATHER_INFO_FAILURE,
  payload: error
})

const parseForecastData = data => {
  let forecast = { city: { name: data.city.name }, list: [] };
  data.list.map(item => {
    const date = localeDate(item.dt);
    const hour = getLocalHour(item.dt);
    const { temp_min, temp_max } = item.main;
    let idx = forecast.list.findIndex(el => el.date === date);
    // if (hour > 5 && hour < 22) {
    item = { ...item, hour: formatHour(hour) };
    if (idx === -1) {
      if (forecast.list.length < 5) {
        forecast.list.push({ date, temp_min, temp_max, list: [item] });
      } // ignore any data in the 6th day as tha details are incomplete.
    } else {
      if (temp_min < forecast.list[idx].temp_min) { forecast.list[idx].temp_min = temp_min }
      if (temp_max > forecast.list[idx].temp_max) { forecast.list[idx].temp_max = temp_max }
      forecast.list[idx].list.push(item);
    }
  });
  console.log("Formatted 5 days forcast", forecast);
  return forecast;
}

export const setBatteryInfo = (batteryLevel, charging) => {
  return dispatch => {
    dispatch({
      type: SET_BATTERY_INFO,
      payload: {
        batteryLevel,
        charging
      }
    })
  }
}

export const setForecastInfo = forecast => {
  return dispatch => {
    dispatch({
      type: SET_FORECAST_INFO,
      payload: parseForecastData(forecast)
    })
  }
}

export const setCityList = cities => {
  return dispatch => {
    dispatch({
      type: SET_CITY_LIST,
      payload: cities
    })
  }
}

export const addCity = city => {
  return dispatch => {
    dispatch({
      type: ADD_CITY,
      payload: city
    })
  }
}

export const removeCity = index => {
  return dispatch => {
    dispatch({
      type: REMOVE_CITY,
      payload: index
    })
  }
}

export const reorderCity = data => {
  return dispatch => {
    dispatch({
      type: REORDER_CITY,
      payload: data
    })
  }
}

export const showClock = isShown => {
  return dispatch => {
    dispatch({
      type: SHOW_CLOCK,
      payload: isShown
    })
  }
}


  // console.log(coords);
  // const { latitude, longitude } = coords;
  // return dispatch => {
  //   const openWeatherMapAPI = `http://api.openweathermap.org/data/2.5/weather?lat=${latitude}&lon=${longitude}&APPID=${OPENWEATHERMAP_API_KEY}&units=metric`;
  //   console.log(openWeatherMapAPI);
  //   // axios.get(openWeatherMapAPI)
  //   //   .then(res => {
  //   //     dispatch(getWeatherInfoSuccess(res.data, latitude, longitude));
  //   //   })
  //   //   .catch(err => {
  //   //     console.log("Weather Error", err);
  //   //     let errMessage = err.message;
  //   //     switch (err.message) {
  //   //       case "Network Error":
  //   //         // errMessage = ERROR_NETWORK_CONNECTION;
  //   //         break;
  //   //       default:
  //   //         errMessage = err.message;
  //   //     }
  //   //     dispatch(getWeatherInfoFailure(errMessage));
  //   //   });
  // }
// }
