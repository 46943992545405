import axios from "axios";
import {
  URL_VIDEO_LIST_API,
  GET_VIDEO_LIST,
  GET_VIDEO_LIST_SUCCESS,
  GET_VIDEO_LIST_FAILURE,
  // SET_VIDEO_URL,
  TOGGLE_VIDEO_TAB,
  COMPLETE_VIDEO_LOADING,
  MAX_YOUTUBE_RESULTS
} from "../constants";

export const getVideoList = () => {
  return dispatch => {
    dispatch(getVideoListStarted());
    axios
      .get(URL_VIDEO_LIST_API + `뉴스/${MAX_YOUTUBE_RESULTS}/`)
      .then(res => {
        dispatch(getVideoListSuccess(res.data));
      })
      .catch(err => {
        dispatch(getVideoListFailure(err.message));
      });
  };
};

const getVideoListStarted = () => ({
  type: GET_VIDEO_LIST
});

const getVideoListSuccess = videos => ({
  type: GET_VIDEO_LIST_SUCCESS,
  payload: {
    videos: [...videos]
  }
});

const getVideoListFailure = error => ({
  type: GET_VIDEO_LIST_FAILURE,
  payload: error
});

// export const setVideoURL = (url, index) => {
//   return dispatch => {
//     dispatch({
//       type: SET_VIDEO_URL,
//       payload: {
//         url,
//         index
//       }
//     });
//   };
// }

export const toggleVideoTab = isVisible => {
  return dispatch => {
    dispatch({
      type: TOGGLE_VIDEO_TAB,
      payload: isVisible
    });
  };
};

export const completeVideoLoading = () => {
  return dispatch => {
    dispatch({
      type: COMPLETE_VIDEO_LOADING
    });
  };
};
